
import {NavLink as Link, Outlet } from 'react-router-dom';
import { Fragment } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { BiChat } from 'react-icons/bi';
import { IoSettingsOutline } from 'react-icons/io5';
import { LuMessageSquare } from 'react-icons/lu';
import { MdGridView } from "react-icons/md";
import SupportNavBar from './SupportNavBar';
import "./Support.css"

const SupportDash = () => {
  const NavLink = ({ isActive }) => ({
    background: isActive ? '#284697' : '',
    color: isActive ? 'white' : ''
  });

  return (
    <Fragment>
        <SupportNavBar/>
      <div className='support-nav'>
        <div className='support-logo'>
          <img src='https://i.ibb.co/vvhxKCL/Frame-1-3x.png' alt='' />
        </div>
        <div className='support-mobile-link'>
          <Link to='/' style={NavLink}>
            <MdGridView style={{fontSize:'24px'}} />
            Overview
          </Link>
          <Link to='/SupportMessage' style={NavLink}>
            <LuMessageSquare style={{fontSize:'24px'}} />
            Public Message
          </Link>
          <Link to='/AdminChat' style={NavLink}>
            <BiChat style={{fontSize:'24px'}} />
            Admin Message
          </Link>
          <Link to='/TrackPackage' style={NavLink}>
            <IoIosSearch style={{fontSize:'24px'}} />
            Track Package
          </Link>
          <Link to='/SupportSetting' style={NavLink}>
            <IoSettingsOutline style={{fontSize:'24px'}} />
            Setting
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default SupportDash;
