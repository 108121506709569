import React from "react";
import {ReactComponent as  EmailIcon} from "../../Assets/ic_round-email.svg";
import {ReactComponent as CallIcon} from "../../Assets/ion_call-sharp.svg";
import ContactForm from "../../Form/ContactForm";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-mother">
      <div className="wrapper">
        <h3>CONTACT US</h3>
        <div className="contact-container">

          <div className="contact-card-1">
            <div className="contact-card-con">
              <span>
                <div className="contact-box">
                  <span>
                    <EmailIcon/> <h3>Email Us</h3>
                  </span>
                  <div className="fixing">
                  <h3>Complaint?</h3>
                  <p>Send us an Email, and we would respond to you shortly</p>
                  <a href="mailto:" target="blank">infosupport@smarttransexpress.com</a>    
                  </div>
                </div>

                <div className="contact-box">
                  <span>
                   <CallIcon/> <h3>Call Us</h3>
                  </span>
                  <div className="fixing">
                  <h3>Customer Service</h3>
                  <p>
                    For assistance with shipment status, delivery, and courier
                    pickups.
                  </p>
                  <a href="tel:" target="blank">+2348120178995</a>
                  </div>
                </div>
                <div className="rear-contact">
                    <ContactForm/>
                </div>
              </span>
              <div className="contact-address">
                <span>
                  <h3>Our Offices</h3>
                  <i className="fa-solid fa-caret-down"></i>
                </span>
                <div className="contact-wrapper">
                  <div className="contact-wrapper-card">
                    <h3>Lagos Office</h3>
                    <p>3, Alh Salami Street Aguda Surulere.</p>
                  </div>
                  <div className="contact-wrapper-card">
                  <h3>Osun Office</h3>
                  <p>3rd Building Beside Adolak Hotel, Opposite Old Governor's Office/ Ayegbaju International Market, Ogo-Oluwa, Osogbo</p>
                  </div>
                  <div className="contact-wrapper-card">
                  <h3>Oyo Office</h3>
                  <p>Coming Soon</p>
                  </div>
                  <div className="contact-wrapper-card">
                    <h3>Ekiti Office</h3>
                    <p>Coming Soon</p>
                  </div>
                  <div className="contact-wrapper-card">
                    <h3>Ogun Office</h3>
                    <p>Coming Soon</p>
                  </div>
                  <div className="contact-wrapper-card">
                    <h3>Ondo Office</h3>
                    <p>Coming Soon</p>
                  </div>
                  <div className="contact-wrapper-card">
                    <h3>Kwara</h3>
                    <p>Coming Soon</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-card-2">
            <ContactForm/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
