import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchAllUsers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/users/users?page=${pageParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch users");
  }

  return response.json();
};

const useFetchAllUsers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["allUsers"], 
    ({ pageParam = 1 }) => fetchAllUsers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalUsers } = lastPage;
        const totalPages = Math.ceil(totalUsers / 10); 
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchAllUsers;
