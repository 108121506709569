import React, { useEffect, useState, useMemo, useContext } from "react";
import useOneTimeToken from "../../libs/useOneTimeToken";
import { Fragment } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import "./Token.css";

const Onetimeverificationcode = () => {
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const inputRefs = useMemo(() => Array.from({ length: 6 }, () => React.createRef()), []);
  const { mutate, data, isLoading, isError, error } = useOneTimeToken();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationOverlay, setVerificationOverlay] = useState(true);
  const [errorState, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { Login } = useContext(AuthContext);

  const storedEmail = localStorage.getItem("email");
  const storedPassword = localStorage.getItem("password");

  useEffect(() => {
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
    }
  }, [storedEmail, storedPassword]);

  const handleButtonClick = async () => {
    try {
      await mutate({ email, password });
    } catch (error) {
      console.error("Error sending token:", error);
    }
  };

  const handleResendButton = async () => {
    try {
      await mutate({ email, password });
    } catch (error) {
      console.error("Error sending token:", error);
    }
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setVerificationOverlay(false);
    }
  }, [isLoading, isError, data]);

  const handleInputChange = (index, value) => {
    const newDigits = [...digits];
    newDigits[index] = value.slice(0, 1); 
    setDigits(newDigits);

    if (value && index < 5) {
      // Move focus to the next input
      inputRefs[index + 1].current.focus();
    } else if (!value && index > 0) {
      // If input is empty, move focus back to the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newDigits = [...digits];
      if (!digits[index] && index > 0) {
        // Clear the previous input and move focus back
        inputRefs[index - 1].current.focus();
        newDigits[index - 1] = "";
      } else {
        // Clear the current input
        newDigits[index] = "";
      }
      setDigits(newDigits);
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();
    if (/^\d{6}$/.test(pastedData)) {
      const newDigits = pastedData.split("");
      setDigits(newDigits);

      // Automatically populate inputs
      newDigits.forEach((digit, index) => {
        if (inputRefs[index]?.current) {
          inputRefs[index].current.value = digit;
        }
      });

      // Move focus to the last field
      inputRefs[5].current.focus();
    }
  };

  const handleTokenLogin = async (e) => {
    e.preventDefault();
    const token = digits.join("");
    if (token.length !== 6) {
      setError("Please enter the complete token.");
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/auth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
            otp: token,
          }),
        }
      );
      const resData = await response.json();
      if (!response.ok) {
        throw new Error(resData.message);
      }
      const user = {
        userId: resData.userId,
        token: resData.token,
        role: resData.role,
      };
      Login(user);
      navigate(user.role === "customer" ? "/Market" : "/");
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {verificationOverlay ? (
        <div className="token-overlay">
          <div className="card-1">
            <img src="https://i.ibb.co/SND4s4y/Downloader-63x.png" alt="" />
          </div>
          <div className="card-2">
            <h3>Verifying Your Identity</h3>
            <div className="wrapper">
              <h4>Authentication Method</h4>
              <button>
                Email Authentication <i className="fa-solid fa-caret-down"></i>
              </button>
              <p>Request for one-time verification code</p>
              {email && (
                <p>
                  {email.slice(0, email.indexOf("@") - 2)}...
                  {email.slice(email.indexOf("@") - 2, email.indexOf("@"))}@{email.slice(email.indexOf("@") + 1)}
                </p>
              )}
              <div className="btn">
                <button onClick={handleButtonClick} disabled={isLoading}>
                  {isLoading ? "Sending..." : "Send"}
                </button>
              </div>
              {data && <p style={{ fontFamily: "Montserrat" }}>{data}</p>}
              {isError && <p style={{ color: "red" }}>{error.message}</p>}
            </div>
          </div>
        </div>
      ) : (
        <div className="one-time-verification">
          <p>Enter 6-digit code sent to your Email</p>
          <form onSubmit={handleTokenLogin}>
            <div className="token-inputs" onPaste={handlePaste}>
              {digits.map((digit, index) => (
                <span key={index}>
                  <input
                    ref={inputRefs[index]}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                  />
                </span>
              ))}
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Verifying..." : "Login"}
            </button>
            {errorState && (
              <p style={{ color: "red", fontFamily: "Montserrat", marginTop: "10px" }}>
                {errorState}
              </p>
            )}
          </form>
          <div className="card">
            <p>Request again for one-time verification code if it is not sent</p>
            <button onClick={handleResendButton} disabled={isLoading}>
              {isLoading ? "Resending..." : "Resend"}
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Onetimeverificationcode;
