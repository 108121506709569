import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const deactivateInvestorAccount = async (investor, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/invest/delete-investors/${investor}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

const useDeactivateInvestorAccount = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((investor) => deactivateInvestorAccount(investor, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('investors');
   
    },
  });
};

export default useDeactivateInvestorAccount;
