import React, { useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import useStatesAndCities from '../libs/fetchStateAndCities';
import useLocalCreateShippingCost from '../libs/useLocalCreateShippingCost';
import "./CreatelocalShippingCost.css";

export default function Createlocalshippingcost() {
  const navigate = useNavigate();
  const createShippingCostMutation = useLocalCreateShippingCost();
  const { data: statesAndCities, isLoading: isFetchingStates } = useStatesAndCities();
  const [formData, setFormData] = useState({
    senderState: "",
    senderStateName: "",
    receiverState: "",
    receiverStateName: "",
    senderCity: "",
    senderCityName: "",
    receiverCity: "",
    receiverCityName: "",
    normalBaseCost: "",
    pickupBaseCost: "",
    additionalCostPerKg: "",
    pickupAdditionalCostPerKg: "",
    itemsValuePercentage: "",
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "senderState" || name === "receiverState") {
      const selectedState = statesAndCities?.states.find((state) => state._id === value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Store the state ID
        [`${name}Name`]: selectedState?.name || "", // Store the state name
      }));
    } else if (name === "senderCity" || name === "receiverCity") {
      const selectedCity = getCitiesForState(formData[name.replace("City", "State")])?.find(
        (city) => city._id === value
      );
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Store the city ID
        [`${name}Name`]: selectedCity?.name || "", // Store the city name
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const getCitiesForState = (stateId) => {
    const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
    return selectedState?.cities || [];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Create a payload with names instead of IDs
      const payload = {
        senderState: formData.senderStateName,
        senderCity: formData.senderCityName,
        receiverState: formData.receiverStateName,
        receiverCity: formData.receiverCityName,
        normalBaseCost: formData.normalBaseCost,
        pickupBaseCost: formData.pickupBaseCost,
        pickupAdditionalCostPerKg: formData.pickupAdditionalCostPerKg,
        additionalCostPerKg: formData.additionalCostPerKg,
        itemsValuePercentage: formData.itemsValuePercentage,
      };

      await createShippingCostMutation.mutateAsync(payload);
      setSuccess('Shipping cost created successfully');
      setTimeout(() => {
        setSuccess(false);
        navigate('/localshippingcost');
      }, 3000);
    } catch (error) {
      console.error("Creation failed:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='super_admin_create-cost'>
      <div className="header">
        <IoArrowBack onClick={() => navigate('/localshippingcost')} />
        <p>Create Shipping Cost</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="wrapper">
          <span>
            <label>Sender State</label>
            <select
              name="senderState"
              value={formData.senderState}
              onChange={handleChange}
              disabled={isFetchingStates}
            >
              <option value="">Select Origin State</option>
              {statesAndCities?.states.map((state) => (
                <option key={state._id} value={state._id}>
                  {state.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label>Sender City</label>
            <select
              name="senderCity"
              value={formData.senderCity}
              onChange={handleChange}
            >
              <option value="">Select Origin City</option>
              {getCitiesForState(formData.senderState).map((city) => (
                <option key={city._id} value={city._id}>
                  {city.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label>Receiver State</label>
            <select
              name="receiverState"
              value={formData.receiverState}
              onChange={handleChange}
              disabled={isFetchingStates}
            >
              <option value="">Select Receiver State</option>
              {statesAndCities?.states.map((state) => (
                <option key={state._id} value={state._id}>
                  {state.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label>Receiver City</label>
            <select
              name="receiverCity"
              value={formData.receiverCity}
              onChange={handleChange}
            >
              <option value="">Select Receiver City</option>
              {getCitiesForState(formData.receiverState).map((city) => (
                <option key={city._id} value={city._id}>
                  {city.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label>Normal Base Cost</label>
            <input
              type="tel"
              name="normalBaseCost"
              value={formData.normalBaseCost}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Pickup Base Cost</label>
            <input
              type="tel"
              name="pickupBaseCost"
              value={formData.pickupBaseCost}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Pick up Additonal Cost Per kg</label>
            <input
              type="tel"
              name="pickupAdditionalCostPerKg"
              value={formData.pickupAdditionalCostPerKg}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Additional Cost Per kg</label>
            <input
              type="tel"
              name="additionalCostPerKg"
              value={formData.additionalCostPerKg}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Items Value Percentage</label>
            <input
              type="tel"
              name="itemsValuePercentage"
              value={formData.itemsValuePercentage}
              onChange={handleChange}
            />
          </span>
        </div>
        <button type="submit" disabled={isLoading || createShippingCostMutation.isLoading}>
          {isLoading || createShippingCostMutation.isLoading ? "Creating...." : "Create"}
        </button>
        {createShippingCostMutation.isError && (
          <p className="error-message">{createShippingCostMutation.error.message}</p>
        )}
        {success && <p className="success-message">{success}</p>}
      </form>
    </div>
  );
}

