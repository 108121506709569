import React, { useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import useFetchAllStates from '../libs/fetchAdminState';
import useCreateShippingCost from '../libs/createShippingCost';
import "./SuperadminCreatecost.css";

const Superadmincreateshipcost = () => {
    const navigate = useNavigate();
    const { data: allStates } = useFetchAllStates();
    const createShippingCostMutation = useCreateShippingCost();
    const [formData, setFormData] = useState({
        sender_state: "",
        receiver_state: "",
        base_cost: "",
        additional_cost_per_half_kg: "",
    });
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); 
        try {
            await createShippingCostMutation.mutateAsync(formData);
            setSuccess('Shipping cost created successfully');
            setTimeout(() => {
                setSuccess(false);
                navigate('/superadminshippingcost');
            }, 3000);
        } catch (error) {
            console.error("Creation failed:", error.message);
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div className='super_admin_create-cost'>
            <div className="header">
                <IoArrowBack onClick={() => navigate('/superadminshippingcost')} />
                <p>Create Shipping Cost</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="wrapper">
                    <span>
                        <label>Sender State</label>
                        <select
                            name="sender_state"
                            value={formData.sender_state}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Sender State</option>
                            {allStates && allStates.map((state) => (
                                <option key={state._id} value={state.state}>
                                    {state.state}
                                </option>
                            ))}
                        </select>
                    </span>
                    <span>
                        <label>Receiver State</label>
                        <select
                            name="receiver_state"
                            value={formData.receiver_state}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Receiver State</option>
                            {allStates && allStates.map((state) => (
                                <option key={state._id} value={state.state}>
                                    {state.state}
                                </option>
                            ))}
                        </select>
                    </span>
                    <span>
                        <label>Base Cost</label>
                        <input type="tel" name='base_cost' value={formData.base_cost} onChange={handleChange} />
                    </span>
                    <span>
                        <label>Additional Cost</label>
                        <input type="tel" name='additional_cost_per_half_kg' value={formData.additional_cost_per_half_kg} onChange={handleChange} />
                    </span>
                </div>
                <button type='submit' disabled={isLoading || createShippingCostMutation.isLoading}>
                    {isLoading || createShippingCostMutation.isLoading ? "Creating...." : "Create"}
                </button>
                {createShippingCostMutation.isError && (
                    <p className="error-message">
                        {createShippingCostMutation.error.message}
                    </p>
                )}
                {success && <p className="success-message">{success}</p>}
            </form>
        </div>
    );
}

export default Superadmincreateshipcost;

