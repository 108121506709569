import React from 'react';
import {ReactComponent as SearchIcon} from '../../Assets/iconoir_search.svg';
import "./super_admin_ontripdriver.css";

export default function Ontripdriver() {
 
  return (
    <div className='super-admin-investor'>
        <div className="header">
            <p>On Trip Drivers</p>
            <div className="in">
                <span>
                    <SearchIcon/>
                <input 
                    type="search" 
                    placeholder='search On trip Driver by id' 
                    />
                </span>
               
            </div>
        </div>
        <div className="stex-investor-table">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);
}
