import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';

const useLocalShippingCost = () => {
  const queryClient = useQueryClient();

  const fetchShippingCost = async ({ senderState, senderCity,  receiverState, receiverCity, weight, quantity, itemsValue, isEmergency,isPickupRequired }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/statecalc/calculate`, {
        senderState: senderState,
        receiverState: receiverState,
        senderCity: senderCity,
        receiverCity: receiverCity,
        weight: weight,
        quantity: quantity,
        itemsValue:itemsValue,
        isEmergency: isEmergency,
        isPickupRequired: isPickupRequired
      });

      return response.data; 
    } catch (error) {
      throw new Error('We are not shipping to that state combination yet, Please try again later.');
    }
  };

  const mutation = useMutation(fetchShippingCost, {
    onSuccess: (data) => {

      queryClient.invalidateQueries('shippingCost');
    },
  });

  return { mutation };
};

export default useLocalShippingCost;
