import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const deactivateAccount = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/delete/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

const useDeactivateAccount = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((userId) => deactivateAccount(userId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('logisticsOfficers');
      queryClient.invalidateQueries('drivers')
      queryClient.invalidateQueries('riders')
      queryClient.invalidateQueries('marketplaceManagers')
      queryClient.invalidateQueries('supports')
      queryClient.invalidateQueries('freightOfficers')
      queryClient.invalidateQueries('chartererOfficers')
      queryClient.invalidateQueries('allUsers')
    },
  });
};

export default useDeactivateAccount;
