import React, { useState } from "react";
import { Fragment } from "react";
import Spinner from "../../Spinner/Spinner";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import RequestCodeForm from "./RequestResetPassword/RequestCodeForm";

const defaultForm = {
  newPassword: "",
  resetCode: "",
};

const ResetPassword = () => {
  const [formInput, setFormInput] = useState(defaultForm);
  const [isLoading, setIsLoading] = useState(false);
  const { newPassword, resetCode } = formInput;
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleConfirmShowPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(false)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/reset-password`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formInput),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setFormInput(defaultForm);
        setIsLoading(false);
        setSuccessMsg("Password reset successfully!");

        setTimeout(() => {
          setSuccessMsg(null);
          navigate("/Login");
        }, 3000);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
    <div className="reset-password-form">
      <h3>Reset Password</h3>
      <form onSubmit={handleSubmit}>
      <span>
          <label>Reset Code</label>
          <div className="pass">
            <input
              type={showConfirmPass ? "text" : "password"}
              name="resetCode"
              value={resetCode}
              onChange={handleChange}
              required
            />
            <div onClick={handleConfirmShowPass}>
              {showConfirmPass ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </span>
        <span>
          <label>New Password</label>
          <div className="pass">
            <input
              type={showPass ? "text" : "password"}
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              required
            />
            <div onClick={handleShowPass}>
              {showPass ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </span>
        {error && <p style={{ color: "red", fontFamily: 'Montserrat' }}>{error}</p>}
        <button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner /> : "Confirm"}
        </button>
        {successMsg && <p style={{ color: "green" , fontFamily: "Montserrat"}}>{successMsg}</p>}
      </form>
      <RequestCodeForm/>
    </div>
  </Fragment>
  );
};

export default ResetPassword;
