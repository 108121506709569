import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context/AuthContext';
import useAdminTruck from '../../libs/fetchAdminTruck';
import Skeleton from 'react-loading-skeleton';
import { FaXmark } from "react-icons/fa6";
import Spinner from '../../../../Spinner/Spinner';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FreightCharter.css';

const defaultForm = {
  fullName: '',
  phoneNumber: '',
  contactName: '',
  address1: '',
  address2: '',
  zipcode: '',
  email: '',
  pickUpLocation: '',
  destination: '',
  hireDate: '',
  returnDate: '',
  // zipcode: ''
}


const AdminOrder = () => {
  const { token } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailOverlay, setDetailOverlay] = useState(false);
  const [orderOverlay, setOrderOverlay] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const { trucks, totalTrucks, isLoading, isError } = useAdminTruck(currentPage);
  const navigate = useNavigate();
  const { fullName, contactName, phoneNumber, address1, address2, email, zipcode, pickUpLocation, destination,  hireDate, returnDate } = form;

  const itemsPerPage = 8;
  const totalPages = Math.ceil(totalTrucks / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalTrucks);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleViewDetails = (truck) => {
    setSelectedTruck(truck);
    setDetailOverlay(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      setLoading(true);
    const payload = {
      truckId: selectedTruck._id,
      price: selectedTruck.pricePerDay,
      fullName: form.fullName,
      contactName: form.contactName,
      email: form.email,
      phoneNumber: form.phoneNumber,
      address1: form.address1,
      address2: form.address2,
      zipcode: form.zipcode,
      pickUpLocation: form.pickUpLocation,
      destination: form.destination,
      hireDate: form.hireDate,
      returnDate: form.returnDate
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/hiretruck/hire`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.message);
      }
      setForm(defaultForm);
      setOrderOverlay(false);
      navigate('/freightbookrequest');
    } catch (error) {
      console.error("Error:", error.message);
      toast.error('Start date can not be more than 24 hours from now.');
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <>
      {orderOverlay && (
        <div className="freight-admin-order-overlay">
          <span>
            <button onClick={() => setOrderOverlay(false)}>
              <FaXmark style={{ fontSize: "30px" }} />
            </button>
          </span>
          <div className="wrapper">
            <form onSubmit={handleSubmit}>
               <div className="freight-combination">
                <span>
                  <label>Full Name</label>
                  <input type="text" name='fullName' value={fullName} onChange={handleChange} required />
                </span>
                <span>
                  <label>Contact Name</label>
                  <input type="text" name='contactName' value={contactName} onChange={handleChange} required />
                </span>
              </div>
              <div className="freight-combination">
                <span>
                  <label>Email</label>
                  <input type="text" name='email' value={email} onChange={handleChange} required />
                </span>
                <span>
                  <label>Phone Number</label>
                  <input type="text" name='phoneNumber' value={phoneNumber} onChange={handleChange} required />
                </span>
              </div>
              <div className="freight-combination">
                <span>
                  <label>Address 1</label>
                  <input type="text" name='address1' value={address1} onChange={handleChange} required />
                </span>
                <span>
                  <label>Address 2</label>
                  <input type="text" name='address2' value={address2} onChange={handleChange} required />
                </span>
              </div>
              <div className="freight-combination">
                <span>
                  <label>Zip-Code</label>
                  <input type="tel" name='zipcode' value={zipcode} onChange={handleChange} required />
                </span>
              </div>
              <div className="freight-combination">
                <span>
                  <label>Pick Up Location (Address & State)</label>
                  <input type="text" name='pickUpLocation' value={pickUpLocation} onChange={handleChange} required />
                </span>
                <span>
                  <label>Destination (Address & State)</label>
                  <input type="text" name='destination' value={destination} onChange={handleChange} required />
                </span>
              </div>
              <div className="freight-combination">
                <span>
                  <label>Hiring Date</label>
                  <input type="date" name='hireDate' value={hireDate} onChange={handleChange} required />
                </span>
                <span>
                  <label>Return Date</label>
                  <input type="date" name='returnDate' value={returnDate} onChange={handleChange} required />
                </span>
              </div>
              <button type="submit" disabled={loading}>
                {loading ? <Spinner /> : "Book"}
              </button>
            </form>
          </div>
          <ToastContainer position="top-right" className="toast" />
        </div>
      )}
      {detailOverlay && selectedTruck && (
        <div className="admin-truck-overlay">
          <div className="wrapper">
            <span>
              <button onClick={() => setDetailOverlay(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="admin-truck-detail">
               <h3> {selectedTruck._id}</h3>
              {selectedTruck.images && selectedTruck.images.length > 0 && (
                <img
                  src={selectedTruck.images[0]}
                  alt={selectedTruck.truckModel}
                />
              )}
              <div className="admin-truck-span">
                <div className="card">
                  <h3>{selectedTruck.truckType}</h3>
                  <p>{selectedTruck.truckModel}</p>
                  <h4>
                    {selectedTruck.pricePerDay
                      ? selectedTruck.pricePerDay.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </h4>
                </div>
                <div className="card">
                  <p>State: {selectedTruck.state}</p>
                </div>
              </div>
              <h3>DESCRIPTION</h3>
              <p>{selectedTruck.description}</p>
              <button onClick={() => setOrderOverlay(true)}>Order</button>
            </div>
          </div>
        </div>
      )}

      <div className="freight-admin-order">
        <div className="wrapper">
          <h3>Freight Admin Order</h3>
        </div>
        <div className="freight-admin-mother">
          <table className="truck-table">
            <thead>
              <tr>
                <th>Truck ID</th>
                <th>Image</th>
                <th>Type</th>
                <th>Model</th>
                <th>State</th>
                <th>Price/Day</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : isError ? (
                <tr>
                  <td colSpan="6"><p style={{ textAlign: 'center' }}>No Truck available now</p></td>
                </tr>
              ) : (
                trucks.map((truck) => (
                  <tr key={truck.id}>
                    <td>{truck._id}</td>
                    <td>
                      {truck.images && truck.images.length > 0 && (
                        <img
                          src={truck.images[0]}
                          alt={truck.truckModel}
                          className="truck-image"
                        />
                      )}
                    </td>
                    <td>{truck.truckType}</td>
                    <td>{truck.truckModel}</td>
                    <td>{truck.state}</td>
                    <td>
                        {truck.pricePerDay
                          ? truck.pricePerDay.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                          : ""}
                      </td>
                    <td><button onClick={() => handleViewDetails(truck)}>Detail</button></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {!isError && (
          <div className="pagination">
            <span>{`Showing ${startIndex} - ${endIndex} of ${totalTrucks}`}</span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminOrder;



