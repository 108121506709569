import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';

const useManagerLocalShippingCost = () => {
  const queryClient = useQueryClient();

  const fetchShippingCost = async ({ senderState, receiverState, weight, quantity, senderCity, receiverCity,  itemsValue,  isEmergency, isPickupRequired }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/statecalc/calculate`, {
        senderState: senderState,
        receiverState: receiverState,
        senderCity: senderCity,
        receiverCity: receiverCity,
        weight: weight,
        quantity: quantity,
        itemsValue: itemsValue,
        isEmergency: isEmergency,
        isPickupRequired: isPickupRequired
      });

      return response.data; 
    } catch (error) {
      throw new Error('Error fetching shipping cost');
    }
  };

  const mutation = useMutation(fetchShippingCost, {
    onSuccess: (data) => {

      queryClient.invalidateQueries('shippingCost');
    },
  });

  return { mutation };
};

export default useManagerLocalShippingCost;