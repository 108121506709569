import "./Logisticsnavbar.css";
import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as BpersonIcon } from "../../asset/bi_person.svg";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import { GoPackage } from "react-icons/go";
import { IoBicycleSharp } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { RiShareForwardBoxFill } from "react-icons/ri";
import { GiShoppingCart } from "react-icons/gi";
import { TbCubeSend } from "react-icons/tb";
import { PiBusLight } from "react-icons/pi";
import { LuBus } from "react-icons/lu";
import { CiSettings } from "react-icons/ci";
import { BiChat } from 'react-icons/bi';

const LogistcsNavbar = () => {
  const { adminData } = UserDataFetcher();

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };
  return (
    <Fragment>
      <div className="logistic_admin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
          <span>{adminData?.user?.last_name}</span>
        </p>
        <div className="side">
          <BpersonIcon className="round" />
          <MenuIcon />
        </div>
      </div>
      <div className="logistic-nav-container">
        <FrameIcon />
        <div className="links">
          <Link to="/" style={NavLinkLCss}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>
          <Link to="/Logisticactivepkg" style={NavLinkLCss}>
          <GoPackage className="icon"/> Active Packages 
          </Link>
          <Link to="/Logissticsacivedps" style={NavLinkLCss}>
          <GoPackage className="icon"/> Active Dispatch 
          </Link>
          <Link to="/Logisticsdriverrider" style={NavLinkLCss}>
          <IoBicycleSharp className="icon" />  Riders
          </Link>
          <Link to="/Logisticcreatecategory" style={NavLinkLCss}>
          <IoCreateOutline className="icon" /> Create Category
          </Link>
          <Link to="/Logisticsender" style={NavLinkLCss}>
          <RiShareForwardBoxFill className="icon" /> Create Packages
          </Link>
          <Link to="/Logisticspackages" style={NavLinkLCss}>
          <GiShoppingCart className="icon" /> Packages
          </Link>
          <Link to="/packagereceipt" style={NavLinkLCss}>
          <GiShoppingCart className="icon" /> Packages Receipts
          </Link>
          <Link to="/Logisticsactivedispatch" style={NavLinkLCss}>
          <GoPackage className="icon"/> Dispatch 
          </Link>
          <Link to="/Logissticspickups" style={NavLinkLCss}>
          <TbCubeSend className="icon" /> Pickups
          </Link>
          <Link to="/Logisticstrips" style={NavLinkLCss}>
          <PiBusLight className="icon" />Local Trips
          </Link>
          <Link to="/Logisticsinterstatetrips" style={NavLinkLCss}>
          <LuBus className="icon" /> Interstate Trips
          </Link>
          <Link to="/Logisticsortcenter" style={NavLinkLCss}>
          <LuBus className="icon" /> Sort Center Arrival
          </Link>
          <Link to='/LogisticBroadcastMsg' style={NavLinkLCss}>
          <BiChat style={{fontSize:'24px'}} />
          Broadcast Message
          </Link>
          <Link to="/Lgisticsettings" style={NavLinkLCss}>
          <CiSettings  className="icon"/> Settings
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default LogistcsNavbar;
