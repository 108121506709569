import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../utils/spinner";
import { ReactComponent as CloudLogo } from "../../Assets/bx_cloud-upload@3x.svg";
import useCreateInvestor from "../libs/useCreateInvestor";
import { IoArrowBack } from "react-icons/io5";
import "./Superadmincreateinvestor.css";

const defaultForm = {
  firstName: "",
  middleName: "",
  lastName: "",
  address: "",
  phoneNumber: "",
  email: "",
  nextOfKinName: "",
  nextOfKinNum: "",
  nextOfKinAddress: "",
  subscriptionAmount: "",
  images: null,
  NIN: "",
  bankName: "",
  accountNumber: "",
  accountName: "",
};

const Superadmincreateinvestor = () => {
  const [form, setForm] = useState(defaultForm);
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();
  const { mutate, isLoading, isError, error } = useCreateInvestor();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2MB limit");
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      images: file,
    }));
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in form) {
      if (key === "images" && form[key]) {
        formData.append(key, form[key]);
      } else if (
        ["phoneNumber", "nextOfKinNum", "subscriptionAmount", "NIN", "accountNumber"].includes(key)
      ) {
        formData.append(key, Number(form[key])); 
      } else {
        formData.append(key, form[key]);
      }
    }


    mutate(formData, {
      onSuccess: () => {
        toast.success("Investor created successfully!");
        setForm(defaultForm);
        setPreview(null);
        navigate("/superadmininvestor");
      },
      onError: (err) => {
        console.error("Mutation Error:", err.response || err.message);
        toast.error(err?.response?.data?.message || "Failed to create investor");
      },
    });
  };



  return (
    <div className="super_admin-create-investor">
      <div className="header">
        <IoArrowBack onClick={() => navigate("/superadmininvestor")} />
        <p>Create Stex Investor</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="passport">
          {preview ? (
            <img src={preview} alt="Passport Preview" className="passport-preview" />
          ) : (
            <>
              <CloudLogo />
              <h3>Upload photograph</h3>
              <p>Size: 2MB, jpg, jpeg, png</p>
            </>
          )}
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileChange}
            required
          />
        </div>
        <div className="suscriber-card">
          <span>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={form.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
          </span>
          <span>
            <label>Middle Name</label>
            <input
              type="text"
              name="middleName"
              value={form.middleName}
              onChange={handleChange}
              placeholder="Middle Name"
              required
            />
          </span>
          <span>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={form.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </span>
          <span>
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Enter Email"
              required
            />
          </span>
          <span>
            <label>Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
              placeholder="Enter Phone Number"
              required
            />
          </span>
          <span>
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={form.address}
              onChange={handleChange}
              placeholder="Enter Address"
              required
            />
          </span>
          <span>
            <label>Subscription Amount</label>
            <input
              type="tel"
              name="subscriptionAmount"
              value={form.subscriptionAmount}
              onChange={handleChange}
              placeholder="Enter Amount"
              required
            />
          </span>
          <span>
            <label>National ID Number</label>
            <input
              type="text"
              name="NIN"
              value={form.NIN}
              onChange={handleChange}
              placeholder="Enter NIN"
              required
            />
          </span>
          <span>
            <label>Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={form.bankName}
              onChange={handleChange}
              placeholder="Enter Bank Name"
              required
            />
          </span>
          <span>
            <label>Account Number</label>
            <input
              type="text"
              name="accountNumber"
              value={form.accountNumber}
              onChange={handleChange}
              placeholder="Enter Account Number"
              required
            />
          </span>
          <span>
            <label>Account Name</label>
            <input
              type="text"
              name="accountName"
              value={form.accountName}
              onChange={handleChange}
              placeholder="Enter Account Name"
              required
            />
          </span>
          <span>
            <label>Next Of Kin Name</label>
            <input
              type="text"
              name="nextOfKinName"
              value={form.nextOfKinName}
              onChange={handleChange}
              placeholder="Enter Name"
              required
            />
          </span>
          <span>
            <label>Next Of Kin Number</label>
            <input
              type="tel"
              name="nextOfKinNum"
              value={form.nextOfKinNum}
              onChange={handleChange}
              placeholder="Enter Phone Number"
              required
            />
          </span>
          <span>
            <label>Next Of Kin Address</label>
            <input
              type="text"
              name="nextOfKinAddress"
              value={form.nextOfKinAddress}
              onChange={handleChange}
              placeholder="Enter Address"
              required
            />
          </span>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <Spinner /> : "Create"}
          </button>
        </div>
        {isError && <p style={{ color: "red", textAlign: "center" , fontFamily:'Montserrat'}}>{error.message}</p>}
      </form>
      <ToastContainer position="top-right" className="toast" />
    </div>
  );
};

export default Superadmincreateinvestor;

