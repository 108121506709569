import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";

const usePackageCreate = () => {
  const queryClient = useQueryClient();

  const [receipt, setReceipt] = useState(false);
  const [recieptData, setReceiptData] = useState(null);
  const [serverError, setServerError] = useState(null);

  const fetchPackage = async (packageData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/prehome/create`, packageData);
      setReceiptData(response.data);
      setReceipt(true);
      console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        // Extract error message from the response
        const errorMessage = error.response.data.message;
        console.error("Server error:", errorMessage);
        setServerError(errorMessage);
      } else {
        console.error("Request error:", error.message);
        setServerError("An error occurred. Please try again.");
      }
      throw error; // Re-throw the error to maintain consistency in React Query
    }
  };

  const mutation = useMutation(fetchPackage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("preShipment");
    },
  });

  return { createPackage: mutation.mutate, mutation, receipt, recieptData, serverError };
};

export default usePackageCreate;

