import React, { useState, useRef, useCallback } from 'react';
import { ReactComponent as Searchicon } from "../../asset/iconamoon_search-light.svg";
import useDispatchDetailsByCreatorId from '../libs/fetchDispatchDetailsByCreatorId';
import Spinner from '../../utils/spinner';
import "./Logisticativedispatches.css"

function Logisticsactivedispatch() {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage, error } = useDispatchDetailsByCreatorId();
  const [searchQuery, setSearchQuery] = useState('');

  const dispatches = data ? data.pages.flatMap(page => page.dispatches) : [];
  const filteredDispatches = dispatches.filter((dispatch) =>
    dispatch.packages[0]?.tracking_number.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const observerRef = useRef();
  const lastDispatchElementRef = useCallback(
    node => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className='logistic-active-dispatch'>
      <div className="header">
        <p>Dispatch</p>
        <span>
          <Searchicon />
          <input
            type="search"
            placeholder="Search by Tracking Number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="dispatch-table">
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p>Error: {error.message}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Tracking ID</th>
                <th>Items Name</th>
                <th>Date Created</th>
                <th>Estimated Delivery Date</th>
                <th>Dispatch Status</th>
                <th>Driver Name</th>
                <th>Rider Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredDispatches.map((dispatch, index) => {
                if (filteredDispatches.length === index + 1) {
                  return (
                    <tr key={dispatch.id} ref={lastDispatchElementRef}>
                      <td>{dispatch.packages[0]?.tracking_number}</td>
                      <td>{dispatch.packages[0]?.name}</td>
                      <td>{new Date(dispatch.packages[0]?.dateCreated).toLocaleDateString()}</td>
                      <td>{new Date(dispatch.packages[0]?.estimated_delivery_date).toLocaleDateString()}</td>
                      <td>{dispatch.isAssignedToRider || dispatch.isAssignedToDriver ? 'Assigned' : 'Not Assigned'}</td>
                      <td>{dispatch.assignedDrivers[0]?.name || 'NA'}</td>
                      <td>{dispatch.assignedRiders[0]?.name || 'NA'}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={dispatch.id}>
                      <td>{dispatch.packages[0]?.tracking_number}</td>
                      <td>{dispatch.packages[0]?.name}</td>
                      <td>{new Date(dispatch.packages[0]?.dateCreated).toLocaleDateString()}</td>
                      <td>{new Date(dispatch.packages[0]?.estimated_delivery_date).toLocaleDateString()}</td>
                      <td>{dispatch.isAssignedToRider || dispatch.isAssignedToDriver ? 'Assigned' : 'Not Assigned'}</td>
                      <td>{dispatch.assignedDrivers[0]?.name || 'NA'}</td>
                      <td>{dispatch.assignedRiders[0]?.name || 'NA'}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Logisticsactivedispatch;

