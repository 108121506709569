import { useMutation } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useCreateInvestor = () => {
  const { token } = useContext(AuthContext);

  const mutation = useMutation(async (formData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/invest/create-investors`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();

    if (!response.ok) {
      console.error("Response Error:", data);
      throw new Error(data.message || 'Something went wrong');
    }

    return data;
  });

  return mutation;
};

export default useCreateInvestor;
