// import { Fragment, useEffect, useState, useContext } from "react";
// import { AuthContext } from "../../Context/AuthContext";
// import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
// import { ReactComponent as EditIcon } from "../../asset/system-uicons_write.svg";
// import { useNavigate } from "react-router-dom";
// import useFormDataSender from "../libs/sender";
// import useFormDataReceiver from "../libs/receiver";
// import usePackageCreate from "../libs/usePackageCreate";
// import ItemsData from "../libs/items";
// import useFetchReceiptDetails from "../libs/fetchReceiptDetails";

// function Logisticssummary() {
//   const { user } = useContext(AuthContext);
//   const { fetchReceiptDetails } = useFetchReceiptDetails();
//   const [totalShippingCost, setTotalShippingCost] = useState(null);
//   const [isOverlayVisible, setIsOverlayVisible] = useState(false);
//   const [overlayMessage, setOverlayMessage] = useState("");
//   const navigate = useNavigate();
//   const [formData] = useFormDataSender("shipFormData");
//   const [receiverFormData] = useFormDataReceiver("recieverFormData");
//   const [summaryformData] = ItemsData("summaryData");
//   const { createPackage, mutation } = usePackageCreate();

//   useEffect(() => {
//     const storedData = localStorage.getItem("shippingSummaryData");
//     if (storedData) {
//       const parsedData = JSON.parse(storedData);
//       setTotalShippingCost(parsedData.totalShippingCost);
//     }
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const storedData = localStorage.getItem("shippingSummaryData");
//     const shippingCost = storedData
//       ? JSON.parse(storedData).totalShippingCost
//       : 0;

//     const packageData = {
//       name: summaryformData.item,
//       weight: parseFloat(summaryformData.weight),
//       quantity: parseInt(summaryformData.quantity),
//       items_value: parseFloat(summaryformData.items_value),
//       shipping_cost: parseFloat(shippingCost),
//       creator: user.userId,
//       sender: {
//         name: formData.name,
//         contact_name: formData.contact_name,
//         phone_number: formData.phone_number,
//         email: formData.email,
//         address_1: formData.address_1,
//         address_2: formData.address_2,
//         zip_code: formData.zip_code,
//         state: formData.state,
//       },
//       receiver: {
//         name: receiverFormData.name,
//         contact_name: receiverFormData.contact_name,
//         phone_number: receiverFormData.phone_number,
//         email: receiverFormData.email,
//         address_1: receiverFormData.address_1,
//         address_2: receiverFormData.address_2,
//         zip_code: receiverFormData.zip_code,
//         state: receiverFormData.state,
//       },
//       estimated_delivery_date: new Date(
//         summaryformData.estimated_delivery_date
//       ),
//       payment_method: summaryformData.payment_method,
//       delivery_method: summaryformData.delivery_method,
//     };

//     if (summaryformData.pickupOption) {
//       packageData.pickupOption = summaryformData.pickupOption;
//     }

//     try {
//       setOverlayMessage("Creating package...");
//       setIsOverlayVisible(true);

//       const createdPackage = await createPackage(packageData);
//       const packageId = createdPackage?.package?._id;

//       if (packageId) {
//         setOverlayMessage("Package created successfully. Downloading receipt...");
//         console.log("Package created successfully with ID:", packageId);

//         // Fetch receipt details and download or display
//         const pdfUrl = await fetchReceiptDetails(packageId);
//         if (pdfUrl) {
//           const link = document.createElement("a");
//           link.href = pdfUrl;
//           link.download = `package_receipt_${packageId}.pdf`;
//           link.click();
//         }

//         setOverlayMessage("Receipt downloaded successfully!");
//         setTimeout(() => {
//           setIsOverlayVisible(false)
//           navigate("/Logisticspackages")
//         }, 3000);
//       } else {
//         console.error("Failed to retrieve package ID from response.");
//       }
//     } catch (error) {
//       console.error("Error creating package:", error);
//       setOverlayMessage("Error creating package. Please try again.");
//       setTimeout(() => setIsOverlayVisible(false), 3000);
//     }
//   };

//   return (
//     <Fragment>
//       {isOverlayVisible && (
//         <div className="overlay-re">
//           <div className="overlay-message-re">{overlayMessage}</div>
//         </div>
//       )}
//       <form onSubmit={handleSubmit} className="logistics-sender">
//         <div className="header">
//           <p>Create Package</p>
//         </div>
//         <div className="summary">
//           <FrameIcon />
//           <div className="sender-info">
//             <div className="in">
//               <p>Ship From/Return to</p>
//               <button onClick={() => navigate("/Logiscticsender")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="info">
//               <p>{formData.name}</p>
//               <p>{formData.contact_name}</p>
//               <p>{formData.address_1}</p>
//               <p>
//                 {formData.state} {formData.zip_code}
//               </p>
//               <p>{formData.phone_number}</p>
//             </div>
//           </div>

//           <div className="sender-info">
//             <div className="in">
//               <p>Ship to</p>
//               <button onClick={() => navigate("/Logisticsreceiever")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="info">
//               <p>{receiverFormData.name}</p>
//               <p>{receiverFormData.contact_name}</p>
//               <p>{receiverFormData.address_1}</p>
//               <p>
//                 {receiverFormData.state} {receiverFormData.zip_code}
//               </p>
//               <p>{receiverFormData.phone_number}</p>
//             </div>
//           </div>
//           <div className="sum">
//             <div className="in">
//               <p>Item Summary</p>
//               <button onClick={() => navigate("/Logisticsitems")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="sum-items">
//               <div className="in">
//                 <p>Delivery Items</p>
//               </div>
//               <div className="items">
//                 <span>
//                   <h3>Category</h3>
//                   <p>{summaryformData.category}</p>
//                 </span>
//                 <span>
//                   <h3>Weight</h3>
//                   <p>{summaryformData.weight}</p>
//                 </span>
//                 <span>
//                   <h3>Quantity</h3>
//                   <p>{summaryformData.quantity}</p>
//                 </span>
//                 <span>
//                   <h3>Value(NGN)</h3>
//                   <p>{summaryformData.items_value}</p>
//                 </span>
//               </div>
//             </div>
//           </div>
//           <div className="ship-cost">
//             <div className="in">
//               <p>Estimate Cost</p>
//             </div>
//             <div className="cost">
//               <p>
//                 {totalShippingCost !== null
//                   ? (totalShippingCost / 10).toLocaleString("en-US", {
//                       style: "currency",
//                       currency: "NGN",
//                     })
//                   : "N/A"}
//               </p>
//             </div>
//           </div>
//           {mutation.errorMsg && (
//             <p style={{ color: "red" }}>{mutation.errorMsg}</p>
//           )}

//           <button
//             type="submit"
//             disabled={mutation.isLoading}
//             className="submit"
//           >
//             {mutation.isLoading ? "Creating..." : "Submit"}
//           </button>
//         </div>
//       </form>
//     </Fragment>
//   );
// }

// export default Logisticssummary;





// import { Fragment, useEffect, useState, useContext } from "react";
// import { AuthContext } from "../../Context/AuthContext";
// import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
// import { ReactComponent as EditIcon } from "../../asset/system-uicons_write.svg";
// import { useNavigate } from "react-router-dom";
// import useFormDataSender from "../libs/sender";
// import useFormDataReceiver from "../libs/receiver";
// import usePackageCreate from "../libs/usePackageCreate";
// import useSummaryData from "../libs/items";
// import useFetchReceiptDetails from "../libs/fetchReceiptDetails";
// import useManagerStatesAndCities from "../libs/fetchManagerStateCities";

// function Logisticssummary() {
//   const { user } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const { fetchReceiptDetails } = useFetchReceiptDetails();
//   const [totalShippingCost, setTotalShippingCost] = useState(null);
//   const [isOverlayVisible, setIsOverlayVisible] = useState(false);
//   const [overlayMessage, setOverlayMessage] = useState("");


//   const [formData] = useFormDataSender("shipFormData");
//   const [receiverFormData] = useFormDataReceiver("recieverFormData");
//   const [summaryformData] = useSummaryData("summaryData");
//   const { createPackage, mutation } = usePackageCreate();
//   const { data: statesAndCities } = useManagerStatesAndCities();

//   const getCitiesForState = (stateId) => {
//     const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
//     return selectedState?.cities || [];
//   };

//   const getStateNameById = (stateId) => {
//     const state = statesAndCities?.states.find((state) => state._id === stateId);
//     return state?.name || "";
//   };

//   const getCityNameById = (stateId, cityId) => {
//     const city = getCitiesForState(stateId).find((city) => city._id === cityId);
//     return city?.name || "";
//   };

//   const senderStateName = getStateNameById(formData.senderState);
//   const senderCityName = formData.senderCity
//     ? getCityNameById(formData.senderState, formData.senderCity)
//     : "Unknown City";
//   const receiverStateName = getStateNameById(receiverFormData.receiverState);
//   const receiverCityName = receiverFormData.receiverCity
//     ? getCityNameById(receiverFormData.receiverState, receiverFormData.receiverCity)
//     : "Unknown City";

//   useEffect(() => {
//     const storedData = localStorage.getItem("shippingSummaryData");
//     if (storedData) {
//       const parsedData = JSON.parse(storedData);
//       setTotalShippingCost(parsedData.amountToPay);
//     }
//     console.log(storedData.amountToPay);
//   }, []);


//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const storedData = localStorage.getItem("shippingSummaryData");
//       if (storedData) {
//         const parsedData = JSON.parse(storedData);
//         const { amountToPay, originalShippingCost, totalDiscount, discountPercentage, isEmergency } = parsedData;

//         const packageData = {
//           name: summaryformData.item,
//           weight: parseFloat(summaryformData.weight),
//           quantity: parseInt(summaryformData.quantity),
//           items_value: parseFloat(summaryformData.items_value),
//           shipping_cost: parseFloat(amountToPay),
//           original_shipping_cost: parseFloat(originalShippingCost),
//           total_discount: parseFloat(totalDiscount),
//           discount_percentage: parseFloat(discountPercentage),
//           isEmergency: isEmergency === "true", 
//           creator: user.userId,
//           sender: {
//             name: formData.name,
//             contact_name: formData.contact_name,
//             phone_number: formData.phone_number,
//             email: formData.email,
//             address_1: formData.address_1,
//             address_2: formData.address_2,
//             zip_code: formData.zip_code,
//             state: senderStateName,
//             city: senderCityName,
//           },
//           receiver: {
//             name: receiverFormData.name,
//             contact_name: receiverFormData.contact_name,
//             phone_number: receiverFormData.phone_number,
//             email: receiverFormData.email,
//             address_1: receiverFormData.address_1,
//             address_2: receiverFormData.address_2,
//             zip_code: receiverFormData.zip_code,
//             state: receiverStateName,
//             city: receiverCityName,
//           },
//           estimated_delivery_date: new Date(summaryformData.estimated_delivery_date),
//           payment_method: summaryformData.payment_method,
//           delivery_method: summaryformData.delivery_method,
//         };

//         if (summaryformData.pickupOption) {
//           packageData.pickupOption = summaryformData.pickupOption;
//         }

//         setOverlayMessage("Creating package...");
//         setIsOverlayVisible(true);

//         const createdPackage = await createPackage(packageData);
//         const packageId = createdPackage?.package?._id;

//         if (packageId) {
//           setOverlayMessage("Package created successfully. Downloading receipt...");
//           const pdfUrl = await fetchReceiptDetails(packageId);

//           if (pdfUrl) {
//             const link = document.createElement("a");
//             link.href = pdfUrl;
//             link.download = `package_receipt_${packageId}.pdf`;
//             link.click();
//           }

//           setOverlayMessage("Receipt downloaded successfully!");
//           localStorage.clear();
//           setTimeout(() => {
//             setIsOverlayVisible(false);
//             navigate("/Logisticspackages");
//           }, 3000);
//         } else {
//           throw new Error("Failed to retrieve package ID from response.");
//         }
//       } else {
//         throw new Error("No shipping summary data found in local storage.");
//       }
//     } catch (error) {
//       console.error("Error creating package:", error);
//       setOverlayMessage("Error creating package. Please try again.");
//       setTimeout(() => setIsOverlayVisible(false), 3000);
//     }
//   };

//   return (
//     <Fragment>
//       {isOverlayVisible && (
//         <div className="overlay-re">
//           <div className="overlay-message-re">{overlayMessage}</div>
//         </div>
//       )}
//       <form onSubmit={handleSubmit} className="logistics-sender">
//         <div className="header">
//           <p>Create Package</p>
//         </div>
//         <div className="summary">
//           <FrameIcon />
//           <div className="sender-info">
//             <div className="in">
//               <p>Ship From/Return to</p>
//               <button type="button" onClick={() => navigate("/Logiscticsender")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="info">
//               <p>{formData.name}</p>
//               <p>{formData.contact_name}</p>
//               <p>{formData.address_1}</p>
//               <p>
//                 {senderStateName} {formData.zip_code}
//               </p>
//               <p>{senderCityName}</p>
//               <p>{formData.phone_number}</p>
//             </div>
//           </div>

//           <div className="sender-info">
//             <div className="in">
//               <p>Ship to</p>
//               <button type="button" onClick={() => navigate("/Logisticsreceiever")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="info">
//               <p>{receiverFormData.name}</p>
//               <p>{receiverFormData.contact_name}</p>
//               <p>{receiverFormData.address_1}</p>
//               <p>
//                 {receiverStateName} {receiverFormData.zip_code}
//               </p>
//               <p>{receiverCityName}</p>
//               <p>{receiverFormData.phone_number}</p>
//             </div>
//           </div>

//           <div className="sum">
//             <div className="in">
//               <p>Item Summary</p>
//               <button type="button" onClick={() => navigate("/Logisticsitems")}>
//                 Edit <EditIcon />
//               </button>
//             </div>
//             <div className="sum-items">
//               <div className="in">
//                 <p>Delivery Items</p>
//               </div>
//               <div className="items">
//                 <span>
//                   <h3>Category</h3>
//                   <p>{summaryformData.category}</p>
//                 </span>
//                 <span>
//                   <h3>Weight</h3>
//                   <p>{summaryformData.weight}</p>
//                 </span>
//                 <span>
//                   <h3>Quantity</h3>
//                   <p>{summaryformData.quantity}</p>
//                 </span>
//                 <span>
//                   <h3>Value (NGN)</h3>
//                   <p>{summaryformData.items_value}</p>
//                 </span>
//               </div>
//             </div>
//           </div>

//           <div className="ship-cost">
//             <div className="in">
//               <p>Estimate Cost</p>
//             </div>
//             <div className="cost">
//               <p>
//                 {totalShippingCost !== null
//                   ? (totalShippingCost ).toLocaleString("en-US", {
//                       style: "currency",
//                       currency: "NGN",
//                     })
//                   : "N/A"}
//               </p>
//             </div>
//           </div>
//           {mutation.errorMsg && (
//             <p style={{ color: "red" }}>{mutation.errorMsg}</p>
//           )}
//           <button
//             type="submit"
//             disabled={mutation.isLoading}
//             className="submit"
//           >
//             {mutation.isLoading ? "Creating..." : "Submit"}
//           </button>
//         </div>
//       </form>
//     </Fragment>
//   );
// }

// export default Logisticssummary;


import { Fragment, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
import { ReactComponent as EditIcon } from "../../asset/system-uicons_write.svg";
import { useNavigate } from "react-router-dom";
import useFormDataSender from "../libs/sender";
import useFormDataReceiver from "../libs/receiver";
import usePackageCreate from "../libs/usePackageCreate";
import useSummaryData from "../libs/items";
import useFetchReceiptDetails from "../libs/fetchReceiptDetails";
import useManagerStatesAndCities from "../libs/fetchManagerStateCities";

function Logisticssummary() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { fetchReceiptDetails } = useFetchReceiptDetails();
  const [totalShippingCost, setTotalShippingCost] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");


  const [formData] = useFormDataSender("shipFormData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [summaryformData] = useSummaryData("summaryData");
  const { createPackage, mutation } = usePackageCreate();
  const { data: statesAndCities } = useManagerStatesAndCities();

  const getCitiesForState = (stateId) => {
    const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
    return selectedState?.cities || [];
  };

  const getStateNameById = (stateId) => {
    const state = statesAndCities?.states.find((state) => state._id === stateId);
    return state?.name || "";
  };

  const getCityNameById = (stateId, cityId) => {
    const city = getCitiesForState(stateId).find((city) => city._id === cityId);
    return city?.name || "";
  };

  const senderStateName = getStateNameById(formData.senderState);
  const senderCityName = formData.senderCity
    ? getCityNameById(formData.senderState, formData.senderCity)
    : "Unknown City";
  const receiverStateName = getStateNameById(receiverFormData.receiverState);
  const receiverCityName = receiverFormData.receiverCity
    ? getCityNameById(receiverFormData.receiverState, receiverFormData.receiverCity)
    : "Unknown City";

  useEffect(() => {
    const storedData = localStorage.getItem("shippingSummaryData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setTotalShippingCost(parsedData.amountToPay);
    }
    console.log(storedData.amountToPay);
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const storedData = localStorage.getItem("shippingSummaryData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const { amountToPay, originalShippingCost, totalDiscount, discountPercentage, isEmergency, isPickupRequired } = parsedData;

        const packageData = {
          name: summaryformData.item,
          weight: parseFloat(summaryformData.weight),
          quantity: parseInt(summaryformData.quantity),
          items_value: parseFloat(summaryformData.items_value),
          shipping_cost: parseFloat(amountToPay),
          original_shipping_cost: parseFloat(originalShippingCost),
          total_discount: parseFloat(totalDiscount),
          discount_percentage: parseFloat(discountPercentage),
          isEmergency: isEmergency === "true", 
          isPickupRequired: isPickupRequired === "true",
          creator: user.userId,
          sender: {
            name: formData.name,
            contact_name: formData.contact_name,
            phone_number: formData.phone_number,
            email: formData.email,
            address_1: formData.address_1,
            address_2: formData.address_2,
            zip_code: formData.zip_code,
            state: senderStateName,
            city: senderCityName,
          },
          receiver: {
            name: receiverFormData.name,
            contact_name: receiverFormData.contact_name,
            phone_number: receiverFormData.phone_number,
            email: receiverFormData.email,
            address_1: receiverFormData.address_1,
            address_2: receiverFormData.address_2,
            zip_code: receiverFormData.zip_code,
            state: receiverStateName,
            city: receiverCityName,
          },
          estimated_delivery_date: new Date(summaryformData.estimated_delivery_date),
          payment_method: summaryformData.payment_method,
          delivery_method: summaryformData.delivery_method,
        };

        if (summaryformData.pickupOption) {
          packageData.pickupOption = summaryformData.pickupOption;
        }

        setOverlayMessage("Creating package...");
        setIsOverlayVisible(true);

        const createdPackage = await createPackage(packageData);
        const packageId = createdPackage?.package?._id;

        if (packageId) {
          setOverlayMessage("Package created successfully. Downloading receipt...");
          const pdfUrl = await fetchReceiptDetails(packageId);

          if (pdfUrl) {
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = `package_receipt_${packageId}.pdf`;
            link.click();
          }

          setOverlayMessage("Receipt downloaded successfully!");
          localStorage.clear();
          setTimeout(() => {
            setIsOverlayVisible(false);
            navigate("/Logisticspackages");
          }, 3000);
        } else {
          throw new Error("Failed to retrieve package ID from response.");
        }
      } else {
        throw new Error("No shipping summary data found in local storage.");
      }
    } catch (error) {
      console.error("Error creating package:", error);
      setOverlayMessage("Error creating package. Please try again.");
      setTimeout(() => setIsOverlayVisible(false), 3000);
    }
  };

  return (
    <Fragment>
      {isOverlayVisible && (
        <div className="overlay-re">
          <div className="overlay-message-re">{overlayMessage}</div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="logistics-sender">
        <div className="header">
          <p>Create Package</p>
        </div>
        <div className="summary">
          <FrameIcon />
          <div className="sender-info">
            <div className="in">
              <p>Ship From/Return to</p>
              <button type="button" onClick={() => navigate("/Logiscticsender")}>
                Edit <EditIcon />
              </button>
            </div>
            <div className="info">
              <p>{formData.name}</p>
              <p>{formData.contact_name}</p>
              <p>{formData.address_1}</p>
              <p>
                {senderStateName} {formData.zip_code}
              </p>
              <p>{senderCityName}</p>
              <p>{formData.phone_number}</p>
            </div>
          </div>

          <div className="sender-info">
            <div className="in">
              <p>Ship to</p>
              <button type="button" onClick={() => navigate("/Logisticsreceiever")}>
                Edit <EditIcon />
              </button>
            </div>
            <div className="info">
              <p>{receiverFormData.name}</p>
              <p>{receiverFormData.contact_name}</p>
              <p>{receiverFormData.address_1}</p>
              <p>
                {receiverStateName} {receiverFormData.zip_code}
              </p>
              <p>{receiverCityName}</p>
              <p>{receiverFormData.phone_number}</p>
            </div>
          </div>

          <div className="sum">
            <div className="in">
              <p>Item Summary</p>
              <button type="button" onClick={() => navigate("/Logisticsitems")}>
                Edit <EditIcon />
              </button>
            </div>
            <div className="sum-items">
              <div className="in">
                <p>Delivery Items</p>
              </div>
              <div className="items">
                <span>
                  <h3>Category</h3>
                  <p>{summaryformData.category}</p>
                </span>
                <span>
                  <h3>Weight</h3>
                  <p>{summaryformData.weight}</p>
                </span>
                <span>
                  <h3>Quantity</h3>
                  <p>{summaryformData.quantity}</p>
                </span>
                <span>
                  <h3>Value (NGN)</h3>
                  <p>{summaryformData.items_value}</p>
                </span>
              </div>
            </div>
          </div>

          <div className="ship-cost">
            <div className="in">
              <p>Estimate Cost</p>
            </div>
            <div className="cost">
              <p>
                {totalShippingCost !== null
                  ? (totalShippingCost ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : "N/A"}
              </p>
            </div>
          </div>
          {mutation.errorMsg && (
            <p style={{ color: "red" }}>{mutation.errorMsg}</p>
          )}
          <button
            type="submit"
            disabled={mutation.isLoading}
            className="submit"
          >
            {mutation.isLoading ? "Creating..." : "Submit"}
          </button>
        </div>
      </form>
    </Fragment>
  );
}

export default Logisticssummary;
