// import { Fragment, useEffect, useState } from "react";
// import useFormData from "../../Context/UseFormData";
// import useFormDataReceiver from "../../Context/UseFormReceiver";
// import SummaryData from "../../Context/SummaryData";
// import usePackageCreate from "../../libs/usePackageCreate";
// import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100@2x.svg";
// import { CiEdit } from "react-icons/ci";
// import { Link } from "react-router-dom";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { useNavigate } from "react-router-dom";

// import "./Ship.css";

// const Summary = () => {
//   const [formData] = useFormData("shipFormData");
//   const [receiverFormData] = useFormDataReceiver("recieverFormData");
//   const [categoryFormData] = SummaryData("summaryData");
//   const [totalShippingCost, setTotalShippingCost] = useState(null);
  
//   const navigate = useNavigate()

//   const { createPackage, mutation, receipt, recieptData, serverError } = usePackageCreate();

//   useEffect(() => {
//     const storedData = localStorage.getItem("shippingSummaryData");
//     if (storedData) {
//       const parsedData = JSON.parse(storedData);
//       setTotalShippingCost(parsedData.totalShippingCost);
//     }
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     const storedData = localStorage.getItem("shippingSummaryData");
//     const shippingCost = storedData
//       ? JSON.parse(storedData).totalShippingCost
//       : 0;
  
//     const packageData = {
//       name: categoryFormData.item,
//       weight: parseFloat(categoryFormData.weight),
//       quantity: parseInt(categoryFormData.quantity),
//       items_value: parseFloat(categoryFormData.items_value),
//       shippingCost: parseFloat(shippingCost),
//       sender: {
//         name: formData.name,
//         contact_name: formData.contact_name,
//         phone_number: formData.phone_number,
//         email: formData.email,
//         address_1: formData.address_1,
//         address_2: formData.address_2,
//         zip_code: formData.zip_code,
//         state: formData.state,
//       },
//       receiver: {
//         name: receiverFormData.name,
//         contact_name: receiverFormData.contact_name,
//         phone_number: receiverFormData.phone_number,
//         email: receiverFormData.email,
//         address_1: receiverFormData.address_1,
//         address_2: receiverFormData.address_2,
//         zip_code: receiverFormData.zip_code,
//         state: receiverFormData.state,
//       },
//       estimated_delivery_date: new Date(
//         categoryFormData.estimated_delivery_date
//       ),
//       payment_method: categoryFormData.payment_method,
//       delivery_method: categoryFormData.delivery_method,
//       branch_full_address: categoryFormData.branch_full_address,
//     };
  
//     if (categoryFormData.pickupOption) {
//       packageData.pickupOption = categoryFormData.pickupOption;
//     }
  
//     if (categoryFormData.pickupTime) {
//       packageData.pickupTime = categoryFormData.pickupTime;
//     }
  
//     if (categoryFormData.pickupDate) {
//       packageData.pickupDate = categoryFormData.pickupDate;
//     }
  
//     try {
//       await createPackage(packageData);
//     } catch (error) {
//       console.error(error); 
//     }
//   };
  

//   const handlePrint = async () => {
//     const pdf = new jsPDF();

//     if (receipt && recieptData) {
//       const receiptContainer = document.querySelector(
//         ".package-receipt-card"
//       );
//       const canvas = await html2canvas(receiptContainer);
//       const imgData = canvas.toDataURL("image/png");
//       pdf.addImage(imgData, "PNG", 10, 10, 200, 200);
//       pdf.save("receipt.pdf");
//       navigate("/");
//     }
//   };

//   return (
//     <Fragment>
//       {receipt && recieptData && (
//         <div className="package-receipt-card">
//           <div className="printing-box">
//           <button onClick={handlePrint}>Print</button>
//           </div>
//           <div className="package-receipt-wrapper-box">
//             <div className="logo-card">
//               <div className="logo-box">
//                 <img
//                   src="https://i.ibb.co/pxkX0P0/Frame-1171277793-2x.png"
//                   alt=""
//                 />
//               </div>
//               <h3>
//                 {new Date(
//                   recieptData.newPreShipment?.dateCreated
//                 ).toLocaleDateString()}
//               </h3>
//             </div>
//             <h3>Dear Customer</h3>
//             <p>The following is proof of delivery for Waybill Number :</p>
//             <div className="package-receipt-span">
//               <h3>{recieptData.waybill.waybillNumber}</h3>
//               <img
//                 src={`data:image/png;base64,${recieptData.waybill.barcode}`}
//                 alt="Barcode"
//               />
//             </div>
//             <div className="package-receipt-info">
//               <div className="package-recipt-con-1">
//                 <span>
//                   <h3>From</h3>
//                 </span>
//                 <span>
//                   <p>{recieptData.newPreShipment.sender.name}</p>
//                   <p>{recieptData.newPreShipment.sender.address_1}</p>
//                   <p>{recieptData.newPreShipment.sender.phone_number}</p>
//                   <p>{recieptData.newPreShipment.sender.state}</p>
//                 </span>
//               </div>
//               <div className="package-recipt-con-2">
//                 <span>
//                   <h3>To</h3>
//                 </span>
//                 <span>
//                   <p>{recieptData.newPreShipment.receiver.name}</p>
//                   <p>{recieptData.newPreShipment.receiver.address_1}, </p>
//                   <p>{recieptData.newPreShipment.receiver.phone_number}</p>
//                   <p>{recieptData.newPreShipment.receiver.state}</p>
//                 </span>
//               </div>
//               <div className="package-recipt-con-3">
//                 <span>
//                   <h3>Payment mode</h3>
//                   <p>{recieptData.newPreShipment.payment_method}</p>
//                 </span>
//               </div>
//             </div>
//             <div className="package-trip-card">
//               <div className="package-trip-span-1">
//                 <h3>{recieptData.newPreShipment.sender.state}</h3>
//                 <h3>- </h3>
//                 <h3>{recieptData.newPreShipment.receiver.state}</h3>
//               </div>
//               <div className="package-trip-span-2">
//                 <span>
//                   <p> Weight</p>
//                   <h3>{`${recieptData.newPreShipment.weight}kg`}</h3>
//                 </span>
//                 <span>
//                   <p>page</p>
//                   <h3>1/1</h3>
//                 </span>
//               </div>
//             </div>
//             <div className="package-info-wrapper">
//               <div className="package-info-wrapper-1">
//                 <h3>Category</h3>
//                 <h3>Weight</h3>
//                 <h3>Quantity</h3>
//                 <h3>Item-Value</h3>
//                 <h3>Cost</h3>
//                 <h3>VAT</h3>
//                 <h3>Total</h3>
//               </div>
//               <div className="package-info-wrapper-2">
//                 <p>{recieptData.newPreShipment.name}</p>
//                 <p>{`${recieptData.newPreShipment.weight}kg`}</p>
//                 <p>{recieptData.newPreShipment.quantity}</p>
//                 <p>{recieptData.newPreShipment.items_value}</p>
//                 <p>
//                   {(
//                     recieptData.newPreShipment.shippingCost / 100
//                   ).toLocaleString("en-NG", {
//                     style: "currency",
//                     currency: "NGN",
//                   })}
//                 </p>
//                 <p>N/A</p>
//                 <h4>
//                   {(
//                     recieptData.newPreShipment.shippingCost / 100
//                   ).toLocaleString("en-NG", {
//                     style: "currency",
//                     currency: "NGN",
//                   })}
//                 </h4>
//               </div>
//             </div>
//             <div className="package-support">
//               <p>Call:013300242</p>/<p>www.stex.com </p>/
//               <p> Email:hello@stex.com</p>
//             </div>
//             <div className="package-condition">
//               <h3>CONDITIONS FOR CARRIAGE</h3>
//               <span>
//                 <p>
//                   1. The AWB is the legal contract document that covers the
//                   carriage of documents, parcels, and cargo.
//                 </p>
//                 <p>
//                   2. Goods must be certified in good condition by the shipper
//                   and the representative of coral courier before acceptance.
//                 </p>
//                 <p>
//                   2.1 The standard tariff of the company applies to the carriage
//                   of every shipment.
//                 </p>
//                 <p>
//                   2.2 Payments must be completed before shipments are recorded
//                   for deliveries except when the said shipper is on accounts
//                   with the company.
//                 </p>
//                 <p>
//                   2.3 The company has the right to inspect all shipments without
//                   prior notice of the shipper which includes the right to open
//                   and examine the shipment.
//                 </p>
//                 <p>
//                   2.4 The receiver/consignee must confirm 100% condition of
//                   shipment before signing to receive at the point of collection
//                   from the courier.
//                 </p>
//                 <p>
//                   3.0 There is provision for (HOLD FOR COLLECTION). This service
//                   can be for 2-3 days to enable the customer to pick from the
//                   nearest location.
//                 </p>
//                 <p>
//                   3.1 The value of each consignment must be declared! Where
//                   otherwise, the company shall assume the value to be
//                   (2,000NGN).
//                 </p>
//                 <p>
//                   3.2 10% of the declared value shall be charged on every
//                   declared goods as insurance.
//                 </p>
//                 <p>
//                   3.3 In case of a missing item or damage in transit, the
//                   company shall be liable to the limit of the liability.
//                 </p>
//                 <p>
//                   3.4 The courier company is not liable for any loss or damage
//                   that is special or indirect even if the risk (FINANCIAL OR
//                   OTHERWISE) associated with such loss or damage was
//                   communicated to the courier after the acceptance of the
//                   shipment.
//                 </p>
//                 <p>
//                   Claims are limited to the clause above. However, if the value
//                   of the shipment was declared the shipper is required to submit
//                   a claim in writing to the customer service within 24 hours of
//                   delivery.
//                 </p>
//                 <p>
//                   4.0 The company has the right to employ or subcontract to act
//                   for it in the delivery of goods and services; and shall have
//                   no responsibility or liabilities to the shipper.
//                 </p>
//               </span>
//             </div>
//           </div>
//         </div>
//       )}

//       <div className="shipping-form-container">
//         <h3>SHIP NOW</h3>
//         <p>
//           Packages and pallets, big and small, we can offer you instant delivery
//           options for your shipping needs, Fill out your shipment details below
//           and we’ll provide services tailored to your specific requirements.
//           Simply pick the option that suits you best, and continue to book.
//         </p>
//         <div className="shipping-line"></div>
//         <div className="shipping-card">
//           <ShippingFrame />
//         </div>
//         <div className="edit-box">
//           <p>Ship From/Return to</p>
//           <Link to="/Sender">
//             <p>Edit</p>
//             <CiEdit style={{ fontSize: "18px" }} />
//           </Link>
//         </div>
//         <div className="package-info">
//           <p> {formData.name}</p>
//           <p> {formData.address_1}</p>
//           <p> {formData.address_2}</p>
//           <p>{formData.zip_code}</p>
//           <p>{formData.email}</p>
//           <p> {formData.phone_number}</p>
//         </div>
//         <div className="edit-box">
//           <p>Ship to</p>
//           <Link to="/PackageReceiver">
//             <p>Edit</p>
//             <CiEdit style={{ fontSize: "18px" }} />
//           </Link>
//         </div>
//         <div className="package-info">
//           <p> {receiverFormData.name}</p>
//           <p> {receiverFormData.address_1}</p>
//           <p> {receiverFormData.address_2}</p>
//           <p>{receiverFormData.zip_code}</p>
//           <p>{receiverFormData.email}</p>
//           <p> {receiverFormData.phone_number}</p>
//         </div>

//         <div className="item-box">
//           <p>Item Summary</p>
//           <Link to="/PackageCategory">
//             <p>Edit</p>
//             <CiEdit style={{ fontSize: "18px" }} />
//           </Link>
//         </div>
//         <div className="package-summary"></div>
//         <form onSubmit={handleSubmit}>
//           <div className="summary-info">
//             <div className="delivery">
//               <h3>Delivery Items</h3>
//             </div>
//             <div className="wrapper">
//               <span>
//                 <h3>Category</h3>
//                 <p>{categoryFormData.category}</p>
//               </span>
//               <span>
//                 <h3>Weight</h3>
//                 <p>{categoryFormData.weight}</p>
//               </span>
//               <span>
//                 <h3>Quantity</h3>
//                 <p>{categoryFormData.quantity}</p>
//               </span>
//               <span>
//                 <h3>Value</h3>
//                 <p>{categoryFormData.items_value}</p>
//               </span>
//             </div>
//           </div>
//           <div className="estimate">
//             <span>
//               <h3>Estimate Cost</h3>
//             </span>

//             <h3>
//               {totalShippingCost !== null
//                 ? (totalShippingCost / 10).toLocaleString("en-US", {
//                     style: "currency",
//                     currency: "NGN",
//                   })
//                 : "N/A"}
//             </h3>
//           </div>

//           {serverError && <p style={{ color: "red", fontFamily:'Montserrat',textAlign:'center' }}>{serverError}</p>}

//           <button type="submit" disabled={mutation.isLoading}>
//             {mutation.isLoading ? "Loading.." : "Submit"}
//           </button>
//         </form>
//       </div>
//     </Fragment>
//   );
// };

// export default Summary;





import { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
import useFormData from "../../Context/UseFormData";
import useFormDataReceiver from "../../Context/UseFormReceiver";
import SummaryData from "../../Context/SummaryData";
import usePackageCreate from "../../libs/usePackageCreate";
import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100@2x.svg";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import "./Ship.css";

const Summary = () => {
  const [formData] = useFormData("shipFormData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [categoryFormData] = SummaryData("summaryData");
  const [totalShippingCost, setTotalShippingCost] = useState(null);

  const navigate = useNavigate();

  const { createPackage, mutation, receipt, recieptData, serverError } = usePackageCreate();

  useEffect(() => {
    const storedData = localStorage.getItem("shippingSummaryData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setTotalShippingCost(parsedData.totalShippingCost);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.info("This feature is currently under maintenance. Please try again later.");
    setTimeout(() => {
      navigate("/Sender");
    }, 5000);
  };

  const handlePrint = async () => {
    const pdf = new jsPDF();

    if (receipt && recieptData) {
      const receiptContainer = document.querySelector(".package-receipt-card");
      const canvas = await html2canvas(receiptContainer);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 200, 200);
      pdf.save("receipt.pdf");
      navigate("/");
    }
  };

  return (
    <Fragment>
      <div className="shipping-form-container">
        <h3>SHIP NOW</h3>
        <p>
          Packages and pallets, big and small, we can offer you instant delivery
          options for your shipping needs. Fill out your shipment details below
          and we’ll provide services tailored to your specific requirements.
        </p>
        <div className="shipping-line"></div>
        <div className="shipping-card">
          <ShippingFrame />
        </div>
        <div className="edit-box">
          <p>Ship From/Return to</p>
          <Link to="/Sender">
            <p>Edit</p>
            <CiEdit style={{ fontSize: "18px" }} />
          </Link>
        </div>
        <div className="package-info">
          <p>{formData.name}</p>
          <p>{formData.address_1}</p>
          <p>{formData.address_2}</p>
          <p>{formData.zip_code}</p>
          <p>{formData.email}</p>
          <p>{formData.phone_number}</p>
        </div>
        <div className="edit-box">
          <p>Ship to</p>
          <Link to="/PackageReceiver">
            <p>Edit</p>
            <CiEdit style={{ fontSize: "18px" }} />
          </Link>
        </div>
        <div className="package-info">
          <p>{receiverFormData.name}</p>
          <p>{receiverFormData.address_1}</p>
          <p>{receiverFormData.address_2}</p>
          <p>{receiverFormData.zip_code}</p>
          <p>{receiverFormData.email}</p>
          <p>{receiverFormData.phone_number}</p>
        </div>

        <div className="item-box">
          <p>Item Summary</p>
          <Link to="/PackageCategory">
            <p>Edit</p>
            <CiEdit style={{ fontSize: "18px" }} />
          </Link>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="summary-info">
            <div className="delivery">
              <h3>Delivery Items</h3>
            </div>
            <div className="wrapper">
              <span>
                <h3>Category</h3>
                <p>{categoryFormData.category}</p>
              </span>
              <span>
                <h3>Weight</h3>
                <p>{categoryFormData.weight}</p>
              </span>
              <span>
                <h3>Quantity</h3>
                <p>{categoryFormData.quantity}</p>
              </span>
              <span>
                <h3>Value</h3>
                <p>{categoryFormData.items_value}</p>
              </span>
            </div>
          </div>
          <div className="estimate">
            <span>
              <h3>Estimate Cost</h3>
            </span>
            <h3>
              {totalShippingCost !== null
                ? (totalShippingCost / 10).toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                : "N/A"}
            </h3>
          </div>

          {serverError && (
            <p
              style={{
                color: "red",
                fontFamily: "Montserrat",
                textAlign: "center",
              }}
            >
              {serverError}
            </p>
          )}

          <button type="submit" disabled={mutation.isLoading}>
            {mutation.isLoading ? "Loading.." : "Submit"}
          </button>
        </form>
      </div>
      <ToastContainer
        position= "top-right"
        autoClose= "3000"
        closeOnClick= "true"
        pauseOnHover= "true"
        className= "toast"
      />
    </Fragment>
  );
};

export default Summary;
