import { useMutation } from 'react-query';
import { AuthContext } from '../../Context/AuthContext'; 
import { useContext } from 'react';

const useLocalCreateShippingCost = () => {
  const { token } = useContext(AuthContext);

  const createShippingCost = async (formData) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/statecalc/createcost`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Creating shipping cost failed');
    }

    return response.json();
  };

  return useMutation(createShippingCost);
};

export default useLocalCreateShippingCost;
