import React from "react";
import { useNavigate } from "react-router-dom";
import useFormData from "../../Context/UseFormData";
import useFormDataReceiver from "../../Context/UseFormReceiver";
import useAllStates from "../../libs/getAllStates";
import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100.svg";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import "./Ship.css";

const PackageReceiver = () => {
  const navigate = useNavigate();
  const [formData] = useFormData("shipFormData");
  const { data: allStates } = useAllStates();
  const [receiverFormData, handleChanges] = useFormDataReceiver("recieverFormData")
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("receiver Form Data:", receiverFormData);
    navigate("/PackageCategory");
  };

  return (
    <div className="shipping-form-container">
      <h3>SHIP NOW</h3>
      <p>
        Packages and pallets, big and small, we can offer you instant delivery
        options for your shipping needs, Fill out your shipment details below
        and we’ll provide services tailored to your specific requirements.
        Simply pick the option that suits you best, and continue to book.
      </p>
      <div className="shipping-line"></div>
      <div className="shipping-card">
        <ShippingFrame />
      </div>
      <div className="edit-box">
        <p>Ship From/Return to</p>
        <Link to="/Sender">
          <p>Edit</p>
          <CiEdit style={{ fontSize: "18px" }} />
        </Link>
      </div>
      <div className="package-info">
        <p> {formData.name}</p>
        <p> {formData.address_1}</p>
        <p> {formData.address_2}</p>
        <p>{formData.zip_code}</p>
        <p>{formData.email}</p>
        <p> {formData.phone_number}</p>
        <p> {formData.state}</p>
      </div>

      <span>
        <p>Where is your shippment going?</p>
      </span>
      <form onSubmit={handleSubmit}>
        <div className="shipping-combination">
          <span>
            <label className="required-label">Full Name or Company Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={receiverFormData.name || ""}
              onChange={handleChanges}
              required
            />
          </span>

          <span>
            <label>Contact Name</label>
            <input
              type="text"
              name="contact_name"
              placeholder="Enter Name"
              value={receiverFormData.contact_name || ""}
              onChange={handleChanges}
              required
            />
          </span>
        </div>

        <div className="shipping-combination-address">
          <span>
            <label className="required-label">Address 1</label>
            <input
              type="text"
              name="address_1"
              placeholder="Enter Address"
              value={receiverFormData.address_1 || ""}
              onChange={handleChanges}
              required
            />
          </span>

          <span>
            <label>Address 2</label>
            <input
              type="text"
              name="address_2"
              placeholder="Enter Address"
              value={receiverFormData.address_2 || ""}
              onChange={handleChanges}
              required
            />
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label className="required-label"> Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter email"
              value={receiverFormData.email || ""}
              onChange={handleChanges}
              required
            />
          </span>

          <span>
            <label>Phone Number</label>
            <div className="input-wrapper">
              <div className="zip-code">
                +234 <i className="fa-solid fa-caret-down"></i>
              </div>
              <input
                type="tel"
                name="phone_number"
                value={receiverFormData.phone_number || ""}
                onChange={handleChanges}
                required
              />
            </div>
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label className="required-label">State</label>
            <select
                name="state"
                value={receiverFormData.state || ""}
                onChange={handleChanges}
                required
              >
                <option value="">Select State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
          </span>

          <span>
            <label className="required-label">Zip Code</label>
            <input
              type="tel"
              name="zip_code"
              placeholder="Enter zip code"
              value={receiverFormData.zip_code || ""}
              onChange={handleChanges}
              required
            />
          </span>
        </div>
        <button>Proceed</button>
      </form>
    </div>
  );
};

export default PackageReceiver;








// import React from "react";
// import { useNavigate } from "react-router-dom";
// import useFormData from "../../Context/UseFormData";
// import useFormDataReceiver from "../../Context/UseFormReceiver";
// import useStatesAndCities from "../../libs/getStateAndCities";
// import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100.svg";
// import { CiEdit } from "react-icons/ci";
// import { Link } from "react-router-dom";
// import "./Ship.css";

// const PackageReceiver = () => {
//   const navigate = useNavigate();
//   const [formData] = useFormData("shipFormData");
//   const { data: statesAndCities } = useStatesAndCities();
//   const [receiverFormData, handleChanges] = useFormDataReceiver("receiverFormData");

//   const getCitiesForState = (stateId) => {
//     const selectedState = statesAndCities?.states?.find((state) => state._id === stateId);
//     return selectedState?.cities || [];
//   };

//   const getStateNameById = (stateId) => {
//     const state = statesAndCities?.states?.find((state) => state._id === stateId);
//     return state?.name || "Unknown State";
//   };

//   const getCityNameById = (stateId, cityId) => {
//     const city = getCitiesForState(stateId)?.find((city) => city._id === cityId);
//     return city?.name || "Unknown City";
//   };

//   const senderStateName = getStateNameById(formData.senderState);
//   const senderCityName = formData.senderCity
//     ? getCityNameById(formData.senderState, formData.senderCity)
//     : "Unknown City";

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const receiverStateName = getStateNameById(receiverFormData.receiverState);
//     const receiverCityName = getCityNameById(receiverFormData.receiverState, receiverFormData.receiverCity);

//     const updatedFormData = {
//       ...receiverFormData,
//       receiverState: receiverStateName,
//       receiverCity: receiverCityName,
//     };

//     console.log("Receiver Form Data:", updatedFormData);
//     navigate("/PackageCategory", { state: updatedFormData });
//   };

//   return (
//     <div className="shipping-form-container">
//       <h3>SHIP NOW</h3>
//       <p>
//         Packages and pallets, big and small, we can offer you instant delivery
//         options for your shipping needs. Fill out your shipment details below,
//         and we’ll provide services tailored to your specific requirements.
//       </p>
//       <div className="shipping-line"></div>
//       <div className="shipping-card">
//         <ShippingFrame />
//       </div>
//       <div className="edit-box">
//         <p>Ship From/Return to</p>
//         <Link to="/Sender">
//           <p>Edit</p>
//           <CiEdit style={{ fontSize: "18px" }} />
//         </Link>
//       </div>
//       <div className="package-info">
//         <p>{formData.name}</p>
//         <p>{formData.address_1}</p>
//         <p>{formData.address_2}</p>
//         <p>{formData.zip_code}</p>
//         <p>{formData.email}</p>
//         <p>{formData.phone_number}</p>
//         <p>{senderStateName}</p>
//         <p>{senderCityName}</p>
//       </div>

//       <span>
//         <p>Where is your shipment going?</p>
//       </span>
//       <form onSubmit={handleSubmit}>
//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Full Name or Company Name</label>
//             <input
//               type="text"
//               name="name"
//               placeholder="Enter Name"
//               value={receiverFormData.name || ""}
//               onChange={handleChanges}
//               required
//             />
//           </span>

//           <span>
//             <label>Contact Name</label>
//             <input
//               type="text"
//               name="contact_name"
//               placeholder="Enter Contact Name"
//               value={receiverFormData.contact_name || ""}
//               onChange={handleChanges}
//               required
//             />
//           </span>
//         </div>

//         <div className="shipping-combination-address">
//           <span>
//             <label className="required-label">Address 1</label>
//             <input
//               type="text"
//               name="address_1"
//               placeholder="Enter Address"
//               value={receiverFormData.address_1 || ""}
//               onChange={handleChanges}
//               required
//             />
//           </span>

//           <span>
//             <label>Address 2</label>
//             <input
//               type="text"
//               name="address_2"
//               placeholder="Enter Address"
//               value={receiverFormData.address_2 || ""}
//               onChange={handleChanges}
//             />
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Email Address</label>
//             <input
//               type="email"
//               name="email"
//               placeholder="Enter Email"
//               value={receiverFormData.email || ""}
//               onChange={handleChanges}
//               required
//             />
//           </span>

//           <span>
//             <label>Phone Number</label>
//             <div className="input-wrapper">
//               <div className="zip-code">+234</div>
//               <input
//                 type="tel"
//                 name="phone_number"
//                 placeholder="Enter Phone Number"
//                 value={receiverFormData.phone_number || ""}
//                 onChange={handleChanges}
//                 required
//               />
//             </div>
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Receiver State</label>
//             <select
//               name="receiverState"
//               value={receiverFormData.receiverState || ""}
//               onChange={handleChanges}
//               required
//             >
//               <option value="">Select Destination State</option>
//               {statesAndCities?.states?.map((state) => (
//                 <option key={state._id} value={state._id}>
//                   {state.name}
//                 </option>
//               ))}
//             </select>
//           </span>
//           <span>
//             <label className="required-label">Receiver City</label>
//             <select
//               name="receiverCity"
//               value={receiverFormData.receiverCity || ""}
//               onChange={handleChanges}
//               disabled={!receiverFormData.receiverState}
//               required
//             >
//               <option value="">Select Destination City</option>
//               {getCitiesForState(receiverFormData.receiverState).map((city) => (
//                 <option key={city._id} value={city._id}>
//                   {city.name}
//                 </option>
//               ))}
//             </select>
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Zip Code</label>
//             <input
//               type="tel"
//               name="zip_code"
//               placeholder="Enter Zip Code"
//               value={receiverFormData.zip_code || ""}
//               onChange={handleChanges}
//               required
//             />
//           </span>
//         </div>
//         <button type="submit">Proceed</button>
//       </form>
//     </div>
//   );
// };

// export default PackageReceiver;
