import React, { Fragment } from "react";
import useGetHomePreShipmentById from "../libs/useGetHomePreShipmentById";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import { useState } from "react";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import useDeleteHomePreShipmentById from "../libs/useDeleteHomePreShipmentById";
import "./Logisticspickupdetails.css";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

function Logisticspickupdetails() {
  const {
    data: packageDetails,
    isLoading,
    isError,
  } = useGetHomePreShipmentById();
  console.log(packageDetails);
  const navigate = useNavigate();
  const deleteHook = useDeleteHomePreShipmentById();

  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteHook.deleteHomePreShipmentById(
        packageDetails?.homePreShipment._id
      );
      setDeleteOverlay(false);
      navigate("/Logissticspickups");
    } catch (error) {
      console.error("Error deleting shipment:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Fragment>
      {deleteOverlay && (
        <div className="ship-delete">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Shipment?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDelete} disabled={isDeleting}>
                {isDeleting ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="pkg-details-container">
        <div className="header">
          <IoMdArrowBack
            onClick={() => navigate("/Logissticspickups")}
            className="icon"
          />
          <h1>Shipment Details</h1>
        </div>
        {isLoading && <Spinner />}
        {isError && <p>An Errror occured!</p>}
        <div className="card">
          {!isLoading && !isError && (
            <>
              <div className="header">
                <p>
                  ID :{" "}
                  {packageDetails?.homePreShipment._id
                    ?.toUpperCase()
                    .slice(0, 8)}
                </p>
                <p>{formatDate(packageDetails?.homePreShipment.dateCreated)}</p>
              </div>
              <div className="btn-ship">
                <button onClick={() => setDeleteOverlay(true)}>Delete</button>
              </div>
              <div className="sender">
                <p>SENDER'S INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Senders Full name</p>
                  <span>{packageDetails?.homePreShipment.sender?.name}</span>
                </div>
                <div className="card">
                  <p>Email</p>
                  <span>{packageDetails?.homePreShipment.sender?.email}</span>
                </div>
                <div className="card">
                  <p>Phone Number</p>
                  <span>
                    {packageDetails?.homePreShipment.sender?.phone_number}
                  </span>
                </div>
                <div className="card">
                  <p>Home Adress</p>
                  <span>
                    {packageDetails?.homePreShipment.sender?.address_1}
                  </span>
                </div>
                <div className="card">
                  <p>Other Adress</p>
                  <span>
                    {packageDetails?.homePreShipment.sender?.address_2}
                  </span>
                </div>
                <div className="card">
                  <p>State</p>
                  <span>{packageDetails?.homePreShipment.sender?.state}</span>
                </div>
                <div className="card">
                  <p>Zip Code</p>
                  <span>
                    {packageDetails?.homePreShipment.sender?.zip_code}
                  </span>
                </div>
              </div>
              <div className="sender">
                <p>RECEIVER’S INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Senders Full name</p>
                  <span>{packageDetails?.homePreShipment.receiver?.name}</span>
                </div>
                <div className="card">
                  <p>Email</p>
                  <span>{packageDetails?.homePreShipment.receiver?.email}</span>
                </div>
                <div className="card">
                  <p>Phone Number</p>
                  <span>
                    {packageDetails?.homePreShipment.receiver?.phone_number}
                  </span>
                </div>
                <div className="card">
                  <p>Home Adress</p>
                  <span>
                    {packageDetails?.homePreShipment.receiver?.address_1}
                  </span>
                </div>
                <div className="card">
                  <p>Other Adress</p>
                  <span>
                    {packageDetails?.homePreShipment.receiver?.address_2}
                  </span>
                </div>
                <div className="card">
                  <p>State</p>
                  <span>{packageDetails?.homePreShipment.receiver?.state}</span>
                </div>
                <div className="card">
                  <p>Zip Code</p>
                  <span>
                    {packageDetails?.homePreShipment.receiver?.zip_code}
                  </span>
                </div>
              </div>
              <div className="sender">
                <p>ITEM(S) INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Item</p>
                  <span>{packageDetails?.homePreShipment.name}</span>
                </div>
                <div className="card">
                  <p>Weight</p>
                  <span>{packageDetails?.homePreShipment.weight}</span>
                </div>
                <div className="card">
                  <p>Quantity</p>
                  <span>{packageDetails?.homePreShipment.quantity}</span>
                </div>
                <div className="card">
                  <p>Payment Method</p>
                  <span>{packageDetails?.homePreShipment.payment_method}</span>
                </div>
                <div className="card">
                  <p>Est. Delivery date</p>
                  <span>
                    {formatDate(
                      packageDetails?.homePreShipment.estimated_delivery_date
                    )}
                  </span>
                </div>
                <div className="card">
                  <p>Valued at</p>
                  <span>
                          {packageDetails?.homePreShipment?.items_value
                            ? packageDetails?.homePreShipment?.items_value.toLocaleString("en-US", {
                                style: "currency",
                                currency: "NGN",
                              })
                            : null}
                        </span>
                </div>
                <div className="card">
                  <p>Shipping Fee</p>
                  <span>
                    {packageDetails?.homePreShipment.shippingCost
                      ? (
                          packageDetails.homePreShipment.shippingCost / 100
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </span>
                </div>
                <div className="card">
                  <p>Delivery Method</p>
                  <span>{packageDetails?.homePreShipment.delivery_method}</span>
                </div>
                <div className="card">
                  <p>Pickup Date</p>
                  <span>{packageDetails?.homePreShipment.pickupDate}</span>
                </div>
                <div className="card">
                  <p>Pick Time</p>
                  <span>{packageDetails?.homePreShipment.pickupTime}</span>
                </div>
                <div className="card">
                  <p>Waybill Number</p>
                  <span>{packageDetails?.homePreShipment.waybillNumber}</span>
                </div>
                <div className="card">
                  <p>Barcode</p>
                  <img
                    src={`data:image/png;base64, ${packageDetails?.homePreShipment.barcode}`}
                    alt="Barcode"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticspickupdetails;
