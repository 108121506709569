import React, { useContext, useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthContext';

const defaultForm = {
    first_name: "",
    last_name: '',
    email: "",
    phone_number: "",
    password: '',
    country_code: "+234"
}

const MerchantForm = () => {
    const { token } = useContext(AuthContext);
    const [formInput, setFormInput] = useState(defaultForm);
    const [error, setError] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { first_name, last_name, email, phone_number, password, country_code } = formInput;
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput({ ...formInput, [name]: value });
        setError(false);
    };

    const handleShowPass = () => {
        setShowPass(!showPass);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(false);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/merchant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formInput)
            });

            const resData = await response.json();

            if (!response.ok) {
                throw new Error(resData.message);
            }

            setFormInput(defaultForm);
            setIsLoading(false);
            setSuccessMsg("Form Submitted successfully!");
            navigate(`/Login`);
            setTimeout(() => {
                setSuccessMsg(false);
            }, 3000);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
            console.log(formInput);
        }
    };

    return (
        <div className="merchant-form-container">
            <span>
                <Link to="/Market">
                    <IoArrowBack style={{ fontSize: "30px" }} />
                </Link>
                <h3>Become A Merchant</h3>
            </span>
            <form onSubmit={handleSubmit}>
                <div className="input-combination-1">
                    <span>
                        <label>First Name</label>
                        <input type="text" name="first_name" value={first_name} onChange={handleChange} required />
                    </span>
                    <span>
                        <label>Last Name</label>
                        <input type="text" name="last_name" value={last_name} onChange={handleChange} required />
                    </span>
                </div>
                <span>
                    <label>Email Address</label>
                    <input type="email" name="email" value={email} onChange={handleChange} required />
                </span>
                <span>
                    <label>Phone Number</label>
                    <div className="input-combination-2">
                        <div className="zip-code">+234 <i className="fa-solid fa-caret-down" name="country_code" value={country_code} onChange={handleChange}></i></div>
                        <input type="tel" name="phone_number" value={phone_number} onChange={handleChange} required />
                    </div>
                </span>
                <span>
                    <label>Password</label>
                    <div className="pass">
                        <input type={showPass ? "text" : "password"} name="password" value={password} onChange={handleChange} required />
                        <wrapper onClick={handleShowPass}>
                            {showPass ? (
                                <LiaEyeSlashSolid />
                            ) : (
                                <LiaEyeSolid />
                            )}
                        </wrapper>
                    </div>
                </span>
                {error && <p style={{ color: 'red', textAlign: "center", fontFamily:'Montserrat' }}>{error}</p>}
                <button type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner /> : "Sign Up"}
                </button>
                {successMsg && <div className="success-message">{successMsg}</div>}
            </form>
        </div>
    )
}

export default MerchantForm;
