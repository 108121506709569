import { useQuery } from "react-query";
import { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext"; 


const fetchInvestorDetails = async (userId, token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/invest/investors/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const useInvestorDetails = (userId) => {
  const { token } = useContext(AuthContext); 

  return useQuery(
    ["investorDetails", userId], 
    () => fetchInvestorDetails(userId, token), 
    {
      enabled: !!userId && !!token, 
    }
  );
};

export default useInvestorDetails;
