import { NavLink as Link, Outlet, useLocation } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as BpersonIcon } from "../../asset/bi_person.svg";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import UserDataFetcher from "../libs/Getadminbyid";
import { PiUsersFourThin } from "react-icons/pi";
import { TfiPackage } from "react-icons/tfi";
import { BsChatRightDots } from "react-icons/bs";
import { IoChatbubbleEllipsesOutline, IoSettingsOutline } from "react-icons/io5";
import { RxTokens } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCarRental } from "react-icons/md";
import { PiWheelchairDuotone } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";
import { TbCurrencyNaira } from "react-icons/tb";
import { GiTruck } from "react-icons/gi";
import "./navbar.css";

const SuperNavbar = () => {
  const location = useLocation();
  const [dropdowns, setDropdowns] = useState({
    logistic: false,
    hirerAdmin: false,
  });

  useEffect(() => {
    const path = location.pathname;
    setDropdowns({
      logistic: path.includes("Superadmindispatches") || path.includes("Superadminlogistic") || path.includes("Superamdindriver") || path.includes("Superadminrider"),
      hirerAdmin: path.includes("superadminfreight") || path.includes("superadmincharterer") || path.includes("Superadminmrarketmanager") || path.includes("Superadminsupport"),
    });
  }, [location]);

  const toggleDropdown = (key) => {
    setDropdowns((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const { adminData } = UserDataFetcher();

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };

  return (
    <Fragment>
      <div className="super_amin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
          <span>{adminData?.user?.last_name}</span>
        </p>
        <div className="side">
          <BpersonIcon className="round" />
          <MenuIcon />
        </div>
      </div>
      <div className="super_admin_container">
        <div className="frame-card">
          <FrameIcon />
        </div>
        <div className="links">
          <Link to="/" style={NavLinkLCss}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>

          <div className="logistic-button" onClick={() => toggleDropdown("logistic")}>
            <span>
              <div className="icon-card">
                <PiUsersFourThin style={{ fontSize: "26px" }} />
                <h3>Logistics Admins</h3>
              </div>
              {dropdowns.logistic ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.logistic && (
              <div className="logistic-drop">
                <Link to="/Superadmindispatches" style={NavLinkLCss}>
                  <TfiPackage className="icon" /> Dispatch Service
                </Link>
                <Link to="/Superadminlogistic" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Logistics Officer
                </Link>
                <Link to="/Superamdindriver" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Driver
                </Link>
                <Link to="/Superadminrider" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Rider
                </Link>
              </div>
            )}
          </div>

          <div className="hirer-button" onClick={() => toggleDropdown("hirerAdmin")}>
            <span>
              <div className="icon-card">
                <PiUsersFourThin style={{ fontSize: "26px" }} />
                <h3>Other Admins</h3>
              </div>
              {dropdowns.hirerAdmin ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.hirerAdmin && (
              <div className="hirer-drop">
                <Link to="/superadminfreight" style={NavLinkLCss}>
                  <GiTruck style={{ fontSize: "26px" }} /> Freight
                </Link>
                <Link to="/superadmincharterer" style={NavLinkLCss}>
                  <MdOutlineCarRental style={{ fontSize: "26px" }} /> Charterer
                </Link>
                <Link to="/Superadminmrarketmanager" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Marketplace Admin
                </Link>
                <Link to="/Superadminsupport" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Support
                </Link>
              </div>
            )}
          </div>

          <Link to="/superadminshippingcost" style={NavLinkLCss}>
            <TbCurrencyNaira className="icon" />
           Nation Shipping Cost
          </Link>
          <Link to="/localshippingcost" style={NavLinkLCss}>
            <TbCurrencyNaira className="icon" />
           Local Shipping Cost
          </Link>
          <Link to="/ontripdriver" style={NavLinkLCss}>
            <PiWheelchairDuotone className="icon" /> On Trip Driver
          </Link>
          <Link to="/superadmininvestor" style={NavLinkLCss}>
            <IoIosPeople className="icon" /> Investors
          </Link>
          <Link to="/superadmintoken" style={NavLinkLCss}>
            <RxTokens className="icon" /> Tokens
          </Link>
          <Link to="/Superadminblog" style={NavLinkLCss}>
            <IoChatbubbleEllipsesOutline className="icon" /> Blog
          </Link>
          <Link to="/Superadminchat" style={NavLinkLCss}>
            <BsChatRightDots className="icon" /> Admin Chat
          </Link>
          <Link to="/Superadminsetting" style={NavLinkLCss}>
            <IoSettingsOutline className="icon" /> Settings
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default SuperNavbar;


