import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as JamIcon } from "../../asset/jam_write-f.svg";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as FilterIcon } from "../../asset/filter.svg";
import useFetchLogisticsOfficers from "../libs/useFetchLogisticsOfficers";
import { ReactComponent as MoreIcon } from "../../asset/more.svg";
import Spinner from "../../utils/spinner";
import { CiCirclePlus } from "react-icons/ci";
import { AuthContext } from '../../Context/AuthContext';
import useSendMessageToUser from "../libs/useSendMessageToUser";
import { useNavigate } from "react-router-dom";
import "./Superadminchat.css";

function Superadminchat() {
  const {
    data: logisticsOfficers,
    isLoading,
    isError,
  } = useFetchLogisticsOfficers();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const { sendMessage } = useSendMessageToUser();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  const officersArray = logisticsOfficers?.logisticsManagers || [];

  const filteredOfficers = officersArray.filter(
    (officer) =>
      officer.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      officer.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      officer.branch_full_address
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const handleUserClick = (userId) => {
    const selected = officersArray.find((officer) => officer._id === userId);
    setSelectedUser(selected);
    fetchMessages(userId);
  };

  const fetchMessages = async (userId) => {
    // TODO: Implement logic to fetch messages for the selected user
    // Set the messages using setMessages
  };

  const handleSendMessage = async () => {
    if (!selectedUser || !messageContent.trim()) {
      return;
    }

    try {
      setIsSending(true); 
      await sendMessage(selectedUser._id, messageContent);
      setMessages([...messages, { content: messageContent, sender: user.userId }]);
      setMessageContent("");
    } catch (error) {
      console.error('Error sending message:', error.message);
    } finally {
      setIsSending(false); 
    }
  };

  useEffect(() => {
    if (filteredOfficers.length > 0) {
      fetchMessages(filteredOfficers[0]._id);
    }
  }, [filteredOfficers]);

  return (
    <div className="super_admin_chat_container">
      <div className="header">
        <p>Admin Chat</p>
        <span onClick={() => navigate("/Superadminbroadcast")}>
          <p>Broadcast Message</p>
          <JamIcon />
        </span>
      </div>
      <div className="wrapper">
        <div className="logs-chats-container">
          <div className="inner">
            <span>
              <SearchIcon />
              <input
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FilterIcon />
            </span>
          </div>

          {isLoading && <Spinner />}
          {isError && <p>Error loading</p>}
          {!isLoading && !isError && (
            <div className="chart-card">
              {filteredOfficers.map((officer) => (
                <div
                  key={officer._id}
                  className="card"
                  onClick={() => handleUserClick(officer._id)}
                >
                  <div className="box">
                    <p>{officer?.first_name.charAt(0)}</p>
                  </div>
                  <p>{officer?.first_name}</p>
                  <p>{officer?.last_name}</p>
                  <p>{officer?.branch_full_address}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {selectedUser && (
          <div className="chat">
            <div className="selected-user-header">
              <div className="upper">
                <p>{selectedUser.first_name}</p>
                <p>{selectedUser.last_name}</p>
              </div>
              <MoreIcon />
            </div>
            <div className="message-body">
              {messages.map((msg, index) => (
                <div key={index} className={msg.sender === user.userId ? 'sent-message' : 'received-message'}>
                  <p>{msg.content}</p>
                </div>
              ))}
            </div>
            <form className="msg" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
              <CiCirclePlus />
              <input
                type="text"
                placeholder="Type message"
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              />
             <button type="submit" disabled={isSending}>
              {isSending ? "Sending..." : "Send"}
            </button>

            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Superadminchat;

