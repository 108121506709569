import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useQueryClient } from 'react-query'; 

const useUpdateInvestorById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient(); 

  const updateInvestorById = async (userId,userData) => {
    console.log('Updating investor with data:', userData);
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/invest/update-investors/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Error updating investor');
    }

    queryClient.invalidateQueries('investors');

    return response.json();
  };

  return { updateInvestorById };
};


export default useUpdateInvestorById;
