import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useGetActivePackages from "../libs/useGetActivePackages";
import { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../utils/spinner";

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function Logisticactivepkg() {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetActivePackages();
  const [searchQuery, setSearchQuery] = useState('');

  const packages = data?.pages.flatMap(page => page.activePackages) || [];

  const filteredPackages = packages.filter(pkg =>
    pkg?.tracking_number?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    node => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="logistic-packages">
      <div className="header">
        <p>Active Packages</p>
        <span>
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by Package Id"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="packages-table">
        {isLoading && <Spinner />}
        {isError && <p>Error loading packages.</p>}
        {filteredPackages.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Package ID</th>
                <th>Sender’s Name</th>
                <th>Receiver’s Name</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Shipping Cost(NGN)</th>
                <th>Date Created</th>
                <th>Estimated Delivery Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredPackages.map((pkg, index) => {
                const isLastElement = index === filteredPackages.length - 1;
                return (
                  <tr
                    key={pkg?._id || index}
                    ref={isLastElement ? lastProductElementRef : null}
                  >
                    <td>{pkg?.tracking_number || "N/A"}</td>
                    <td>{pkg?.sender?.name || "N/A"}</td>
                    <td>{pkg?.receiver?.name || "N/A"}</td>
                    <td>{pkg?.name || "N/A"}</td>
                    <td>{pkg?.quantity || "N/A"}</td>
                    <td>
                      {pkg?.shipping_cost
                        ? (pkg.shipping_cost ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : "N/A"}
                    </td>
                    <td>
                      {pkg?.dateCreated
                        ? formatDate(pkg.dateCreated)
                        : "N/A"}
                    </td>
                    <td>
                      {pkg?.estimated_delivery_date
                        ? formatDate(pkg.estimated_delivery_date)
                        : "N/A"}
                    </td>
                    <td>
                      {pkg?._id ? (
                        <Link to={`/details/${pkg._id}`}>View Details</Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          !isLoading && (
            <p style={{ fontFamily: 'Montserrat' }}>No packages found.</p>
          )
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Logisticactivepkg;
