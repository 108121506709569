import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const usePackageReceipt = () => {
  const { user, token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["packageReceipts", user?.userId],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/receipt/getall/${user.userId}?page=${pageParam}`,
        
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    {
      enabled: !!user?.userId && !!token,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalLogs / 10)
          ? nextPage
          : undefined;
      },
    }
  );
};

export default usePackageReceipt;



