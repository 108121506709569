import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import useFormData from "../../Context/UseFormData";
import useFormDataReceiver from '../../Context/UseFormReceiver';
import SummaryData from '../../Context/SummaryData';
import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100 (1).svg";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import useShippingCost from '../../libs/useShippingCost';
import Spinner from '../Spinner/Spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Ship.css";

const PackageCategory = () => {
  const navigate = useNavigate();
  const [formData] = useFormData("shipFormData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData")
  const [summaryformData, handlesummaryChange] = SummaryData('summaryData')
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [items_value, setItems_value] = useState(summaryformData.items_value || "");

  const { mutation } = useShippingCost();

  const handleCheckBox = () => {
    setIsCheckBox(!isCheckBox);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const data = await mutation.mutateAsync({
        sender_state: formData.state,
        receiver_state: receiverFormData.state,
        weight: summaryformData.weight,
        quantity: summaryformData.quantity,
        items_value: summaryformData.items_value
      });
  
      if (data) {
        localStorage.setItem("shippingSummaryData", JSON.stringify(data));
        navigate("/Summary");
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry we are not shipping to these state combination yet, Please try again later.");
  
      setTimeout(() => {
        navigate('/Sender');
      }, 2000); 
    }
  };
  


  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, ""); 
    inputValue = Number(inputValue) / 100; 

    const formattedValue = inputValue.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    
    setItems_value(formattedValue);

    handlesummaryChange({
      target: { name: "items_value", value: inputValue.toString()},
    });
  };

  return (
    <div className="shipping-form-container">
      <h3>SHIP NOW</h3>
      <p>
        Packages and pallets, big and small, we can offer you instant delivery
        options for your shipping needs. Fill out your shipment details below
        and we’ll provide services tailored to your specific requirements.
        Simply pick the option that suits you best, and continue to book.
      </p>
      <div className="shipping-line"></div>
      <div className="shipping-card">
        <ShippingFrame />
      </div>
      <div className="edit-box">
        <p>Ship From/Return to</p>
        <Link to="/Sender">
          <p>Edit</p>
          <CiEdit style={{ fontSize: '18px'}} />
        </Link>
      </div>
      <div className="package-info">
        <p> {formData.name}</p>
        <p> {formData.address_1}</p>
        <p> {formData.address_2}</p>
        <p>{formData.zip_code}</p>
        <p>{formData.email}</p>
        <p> {formData.phone_number}</p>
        <p> {formData.state}</p>
      </div>
      <div className="edit-box">
        <p>Ship to</p>
        <Link to="/PackageReceiver">
          <p>Edit</p>
          <CiEdit style={{ fontSize: '18px'}} />
        </Link>
      </div>
      <div className="package-info">
        <p> {receiverFormData.name}</p>
        <p> {receiverFormData.address_1}</p>
        <p> {receiverFormData.address_2}</p>
        <p>{receiverFormData.zip_code}</p>
        <p>{receiverFormData.email}</p>
        <p> {receiverFormData.phone_number}</p>
        <p> {receiverFormData.state}</p>
      </div>

      <span>
        <p>What are you Shipping?</p>
      </span>
      <form onSubmit={handleSubmit}>
        <div className="shipping-combination">
          <span>
            <label>Package Category</label>
            <input type="text" name="category" value={summaryformData.category} onChange={handlesummaryChange} required />
          </span>

          <span>
            <label>Package Item</label>
            <input type="text" name="item" value={summaryformData.item} onChange={handlesummaryChange} required />
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label>Weight range (kg)</label>
            <input type="tel" name="weight" value={summaryformData.weight} onChange={handlesummaryChange} required />
          </span>

          <span>
            <label>Quantity</label>
            <input type="text" name="quantity" value={summaryformData.quantity} onChange={handlesummaryChange} required />
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label>Estimated delivery date</label>
            <input
              type="date"
              name="estimated_delivery_date"
              value={summaryformData.estimated_delivery_date || ""}
              onChange={handlesummaryChange}
              required
            />
          </span>

          <span>
            <label>Value(Naira)</label>
            <input
              type="tel"
              name="items_value"
              placeholder="Enter value"
              value={items_value || ""}
              onChange={handleItems_valueChange}
              required
            />
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label>Drop Off Location</label>
            <select name="branch_full_address" value={summaryformData.branch_full_address || ""} required onChange={handlesummaryChange}>
              <option value="">Select Drop Off Location</option>
              <option value="Branch 1">Branch 1</option>
              <option value="Branch 2">Branch 2</option>
              <option value="Branch 3">Branch 3</option>
              <option value="Branch 4">Branch 4</option>
            </select>            
          </span>
          <span>
            <label>Delivery Method</label>
            <select name="delivery_method" value={summaryformData.delivery_method || ""} required onChange={handlesummaryChange}>
              <option value="">Select Delivery Method</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
            </select>            
          </span>
        </div>
        <span>
          <label>Payment Method</label>
          <select name="payment_method" value={summaryformData.payment_method || ""} required onChange={handlesummaryChange}>
            <option value="">Select Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="POS">POS</option>
          </select>            
        </span>

        <div className="check-box">
          <span>
            <input
              type="checkbox"
              name="pickupOption"
              value={summaryformData.pickupOption}
              checked={isCheckBox}
              onChange={handleCheckBox}
            />
            <label>Request Pickup (optional)</label>
          </span>
          {isCheckBox ? (
            <div className="shipping-date-time">
              <span>
                <label>Pickup Date</label>
                <input
                  type="date"
                  name="pickupDate"
                  placeholder="Select date"
                  value={summaryformData.pickupDate || ""}
                  onChange={handlesummaryChange} 
                />
              </span>

              <span>
                <label>Pickup Time</label>
                <input
                  type="time"
                  name="pickupTime"
                  placeholder="Select Time"
                  value={summaryformData.pickupTime || ""}
                  onChange={handlesummaryChange}
                />
              </span>
            </div>
          ) : null}
        </div>
        <button>{mutation.isLoading ? <Spinner /> : "Proceed"}</button>
      </form>
      <ToastContainer  className="toast-container"/>
    </div>
  );
};

export default PackageCategory;









// import React, { useState, useMemo } from 'react';
// import { useNavigate } from "react-router-dom";
// import useFormData from "../../Context/UseFormData";
// import useFormDataReceiver from '../../Context/UseFormReceiver';
// import SummaryData from '../../Context/SummaryData';
// import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100 (1).svg";
// import { CiEdit } from "react-icons/ci";
// import { Link } from "react-router-dom";
// import useLocalShippingCost from '../../libs/fetchLocalShippingCost';
// import useStatesAndCities from '../../libs/getStateAndCities';
// import Spinner from '../Spinner/Spinner';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./Ship.css";

// const PackageCategory = () => {
//   const navigate = useNavigate();
//   const [formData] = useFormData("shipFormData");
//   const [receiverFormData] = useFormDataReceiver("receiverFormData");
//   const [summaryformData, handlesummaryChange] = SummaryData("summaryData");
//   const [isCheckBox, setIsCheckBox] = useState(false);
//   const [items_value, setItems_value] = useState(summaryformData.items_value || "");
//   const { data: statesAndCities } = useStatesAndCities();
//   const { mutation } = useLocalShippingCost();

//   const handleCheckBox = () => {
//     setIsCheckBox(!isCheckBox);
//   };

//   const getCitiesForState = (stateId) => {
//     const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
//     return selectedState?.cities || [];
//   };

//   const getStateNameById = (stateId) => {
//     const state = statesAndCities?.states.find((state) => state._id === stateId);
//     return state?.name || "";
//   };

//   const getCityNameById = (stateId, cityId) => {
//     const city = getCitiesForState(stateId).find((city) => city._id === cityId);
//     return city?.name || "";
//   };

//   const senderStateName = useMemo(() => getStateNameById(formData.senderState), [formData.senderState, statesAndCities]);
//   const senderCityName = useMemo(() => formData.senderCity ? getCityNameById(formData.senderState, formData.senderCity) : "Unknown City", [formData.senderState, formData.senderCity, statesAndCities]);
//   const receiverStateName = useMemo(() => getStateNameById(receiverFormData.receiverState), [receiverFormData.receiverState, statesAndCities]);
//   const receiverCityName = useMemo(() => receiverFormData.receiverCity ? getCityNameById(receiverFormData.receiverState, receiverFormData.receiverCity) : "Unknown City", [receiverFormData.receiverState, receiverFormData.receiverCity, statesAndCities]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const numericItemsValue = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));

//     try {
//       const data = await mutation.mutateAsync({
//         senderState: senderStateName,
//         senderCity: senderCityName,
//         receiverState: receiverStateName,
//         receiverCity: receiverCityName,
//         weight: summaryformData.weight,
//         quantity: summaryformData.quantity,
//         itemsValue: numericItemsValue,
//       });

//       if (data) {
//         localStorage.setItem("shippingSummaryData", JSON.stringify(data));
//         navigate("/Summary");
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Sorry we are not shipping to these state combinations yet. Please try again later.");
//       setTimeout(() => {
//         navigate('/Sender');
//       }, 2000);
//     }
//   };

//   const handleItems_valueChange = (event) => {
//     let inputValue = event.target.value.replace(/[^\d]/g, ""); 
//     inputValue = Number(inputValue) / 100; 

//     const formattedValue = inputValue.toLocaleString("en-US", {
//       style: "currency",
//       currency: "NGN",
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });

//     setItems_value(formattedValue);

//     handlesummaryChange({
//       target: { name: "items_value", value: inputValue.toString() },
//     });
//   };

//   return (
//     <div className="shipping-form-container">
//       <h3>SHIP NOW</h3>
//       <p>
//         Packages and pallets, big and small, we can offer you instant delivery
//         options for your shipping needs. Fill out your shipment details below
//         and we’ll provide services tailored to your specific requirements.
//         Simply pick the option that suits you best, and continue to book.
//       </p>
//       <div className="shipping-line"></div>
//       <div className="shipping-card">
//         <ShippingFrame />
//       </div>
//       <div className="edit-box">
//         <p>Ship From/Return to</p>
//         <Link to="/Sender">
//           <p>Edit</p>
//           <CiEdit style={{ fontSize: '18px'}} />
//         </Link>
//       </div>
//       <div className="package-info">
//         <p>{formData.name}</p>
//         <p>{formData.address_1}</p>
//         <p>{formData.address_2}</p>
//         <p>{formData.zip_code}</p>
//         <p>{formData.email}</p>
//         <p>{formData.phone_number}</p>
//         <p>{senderStateName}</p>
//         <p>{senderCityName}</p>
//       </div>
//       <div className="edit-box">
//         <p>Ship to</p>
//         <Link to="/PackageReceiver">
//           <p>Edit</p>
//           <CiEdit style={{ fontSize: '18px'}} />
//         </Link>
//       </div>
//       <div className="package-info">
//         <p>{receiverFormData.name}</p>
//         <p>{receiverFormData.address_1}</p>
//         <p>{receiverFormData.address_2}</p>
//         <p>{receiverFormData.zip_code}</p>
//         <p>{receiverFormData.email}</p>
//         <p>{receiverFormData.phone_number}</p>
//         <p>{receiverStateName}</p>
//         <p>{receiverCityName}</p>
//       </div>
//       <form onSubmit={handleSubmit}>
//         <div className="shipping-combination">
//           <span>
//             <label>Package Category</label>
//             <input type="text" name="category" value={summaryformData.category} onChange={handlesummaryChange} required />
//           </span>

//           <span>
//             <label>Package Item</label>
//             <input type="text" name="item" value={summaryformData.item} onChange={handlesummaryChange} required />
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label>Weight range (kg)</label>
//             <input type="tel" name="weight" value={summaryformData.weight} onChange={handlesummaryChange} required />
//           </span>

//           <span>
//             <label>Quantity</label>
//             <input type="text" name="quantity" value={summaryformData.quantity} onChange={handlesummaryChange} required />
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label>Estimated delivery date</label>
//             <input
//               type="date"
//               name="estimated_delivery_date"
//               value={summaryformData.estimated_delivery_date || ""}
//               onChange={handlesummaryChange}
//               required
//             />
//           </span>

//           <span>
//             <label>Value(Naira)</label>
//             <input
//               type="tel"
//               name="items_value"
//               placeholder="Enter value"
//               value={items_value || ""}
//               onChange={handleItems_valueChange}
//               required
//             />
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label>Drop Off Location</label>
//             <select name="branch_full_address" value={summaryformData.branch_full_address || ""} required onChange={handlesummaryChange}>
//               <option value="">Select Drop Off Location</option>
//               <option value="Branch 1">Branch 1</option>
//               <option value="Branch 2">Branch 2</option>
//               <option value="Branch 3">Branch 3</option>
//               <option value="Branch 4">Branch 4</option>
//             </select>            
//           </span>
//           <span>
//             <label>Delivery Method</label>
//             <select name="delivery_method" value={summaryformData.delivery_method || ""} required onChange={handlesummaryChange}>
//               <option value="">Select Delivery Method</option>
//               <option value="Home">Home</option>
//               <option value="Office">Office</option>
//             </select>            
//           </span>
//         </div>
//         <span>
//           <label>Payment Method</label>
//           <select name="payment_method" value={summaryformData.payment_method || ""} required onChange={handlesummaryChange}>
//             <option value="">Select Payment Method</option>
//             <option value="Cash">Cash</option>
//             <option value="POS">POS</option>
//           </select>            
//         </span>

//         <div className="check-box">
//           <span>
//             <input
//               type="checkbox"
//               name="pickupOption"
//               value={summaryformData.pickupOption}
//               checked={isCheckBox}
//               onChange={handleCheckBox}
//             />
//             <label>Request Pickup (optional)</label>
//           </span>
//           {isCheckBox ? (
//             <div className="shipping-date-time">
//               <span>
//                 <label>Pickup Date</label>
//                 <input
//                   type="date"
//                   name="pickupDate"
//                   placeholder="Select date"
//                   value={summaryformData.pickupDate || ""}
//                   onChange={handlesummaryChange} 
//                 />
//               </span>

//               <span>
//                 <label>Pickup Time</label>
//                 <input
//                   type="time"
//                   name="pickupTime"
//                   placeholder="Select Time"
//                   value={summaryformData.pickupTime || ""}
//                   onChange={handlesummaryChange}
//                 />
//               </span>
//             </div>
//           ) : null}
//         </div>
//         <button>{mutation.isLoading ? <Spinner /> : "Proceed"}</button>
//       </form>
//       <ToastContainer className="toast-container" />
//     </div>
//   );
// };

// export default PackageCategory;
