import React, { useState, useCallback, useRef, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { CiEdit } from "react-icons/ci";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { useNavigate } from "react-router-dom";
import useInvestors from "../libs/useFetchInvestor";
import useUpdateInvestorById from "../libs/useUpdateInvestor";
import useDeactivateInvestorAccount from "../libs/useDeactivateInvestor";
import useInvestorDetails from "../libs/useInvestorDetails";
import Spinner from "../../utils/spinner";
import "./Superadmininvestors.css";

const Superadmininvestor = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [detailOverlay, setDetailOverlay] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);

  const updateInvestor = useUpdateInvestorById();
  const deactivateInvestor = useDeactivateInvestorAccount();

  const {
    data: investorsData,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInvestors();

  const {
    data: investorDetails,
    isLoading: isDetailLoading,
    isError: isDetailError,
  } = useInvestorDetails(selectedInvestorId);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    address: "",
    nextOfKinName: "",
    nextOfKinNum: "",
    nextOfKinAddress: "",
    subscriptionAmount: "",
    NIN: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const filteredInvestors = investorsData?.pages
    ?.flatMap((page) => page.investors)
    ?.filter((investor) =>
      investor.phoneNumber
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  const observerRef = useRef();
  const lastInvestorRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

 

  useEffect(() => {
    if (!isLoading && selectedInvestorId) {
      const allInvestors =
        investorsData?.pages.flatMap((page) => page.investors) || [];

      const selectedInvestor = allInvestors.find(
        (investor) => investor._id === selectedInvestorId
      );

      if (selectedInvestor) {
        setFormData({
          firstName: selectedInvestor.firstName || "",
          lastName: selectedInvestor.lastName || "",
          middleName: selectedInvestor.middleName || "",
          email: selectedInvestor.email || "",
          address: selectedInvestor.address || "",
          phoneNumber: selectedInvestor.phoneNumber || "",
          nextOfKinName: selectedInvestor.nextOfKinName || "",
          nextOfKinNum: selectedInvestor.nextOfKinNum || "",
          nextOfKinAddress: selectedInvestor.nextOfKinAddress || "",
          subscriptionAmount: selectedInvestor.subscriptionAmount || "",
          NIN: selectedInvestor.NIN || "",
          bankName: selectedInvestor.bankName || "",
          accountName: selectedInvestor.accountName || "",
          accountNumber: selectedInvestor.accountNumber || "",
        });
      } else {
        setFormData({
          firstName: "",
          lastName: "",
          middleName: "",
          email: "",
          phoneNumber: "",
          address: "",
          nextOfKinName: "",
          nextOfKinNum: "",
          nextOfKinAddress: "",
          subscriptionAmount: "",
          NIN: "",
          bankName: "",
          accountName: "",
          accountNumber: "",
        });
      }
    }
  }, [selectedInvestorId, isLoading, investorsData]);

  const handleCancelEdit = () => {
    setSelectedInvestorId(null);
    setEditOverlay(false);
  };

  const handleEditClick = (investor) => {
    if (investor) {
      setSelectedInvestorId(investor._id);
      setFormData({
        firstName: investor.firstName || "",
        lastName: investor.lastName || "",
        middleName: investor.middleName || "",
        email: investor.email || "",
        address: investor.address || "",
        phoneNumber: investor.phoneNumber || "",
        nextOfKinName: investor.nextOfKinName || "",
        nextOfKinNum: investor.nextOfKinNum || "",
        nextOfKinAddress: investor.nextOfKinAddress || "",
        subscriptionAmount: investor.subscriptionAmount || "",
        bankName: investor.bankName || "",
        NIN: investor.NIN || "",
        accountName: investor.accountName || "",
        accountNumber: investor.accountNumber || "",
      });
      setEditOverlay(true);
    }
  };


  const handleUpdateInvestor = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedInvestorId) {
        await updateInvestor.updateInvestorById(selectedInvestorId, formData);
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update investor:", error.message);
    } finally {
      setIsUpdating(false);
      setEditOverlay(false);
    }
  };

  const handleDeactivateInvestor = async () => {
    try {
      if (!selectedInvestorId) {
        console.error("Investor ID not available.");
        return;
      }

      await deactivateInvestor.mutateAsync(selectedInvestorId);
      setDeleteOverlay(false);
      setSelectedInvestorId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  const handleViewDetails = (investor) => {
    setSelectedInvestorId(investor._id);
    setDetailOverlay(true);
  };

  return (
    <>
      {detailOverlay && (
        <div className="investor-details-overlay">
          <div className="details-card">
            <span>
              <h3>ID: {investorDetails?.investor?._id}</h3>
              <button onClick={() => setDetailOverlay(false)}>Close</button>
            </span>
            {isDetailLoading && <Spinner />}
            {isDetailError && <p>Error loading details.</p>}
            {investorDetails && !isDetailLoading && (
              <div className="wrapper">
                <img
                  src={investorDetails?.investor?.images[0]}
                  alt={investorDetails.investor.lastName}
                />
                <div className="investor-info">
                  <h2>{` ${investorDetails.investor.lastName} ${investorDetails?.investor?.firstName} ${investorDetails?.investor.middleName}`}</h2>
                  <span>
                    <p>Email:</p>
                    <p> {investorDetails?.investor?.email}</p>
                  </span>
                  <span>
                    <p>Phone:</p>
                    <p>{investorDetails?.investor?.phoneNumber}</p>
                  </span>
                  <span>
                    <p>NIN</p>
                    <p>{investorDetails?.investor?.NIN}</p>
                  </span>
                  <span>
                    <p>Address:</p>
                    <p>{investorDetails?.investor?.address}</p>
                  </span>
                  <span>
                    <p> Suscription Amount:</p>
                    <h3>
                      {investorDetails?.investor?.subscriptionAmount.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )}
                    </h3>
                  </span>
                  <span>
                    <p>Bank Name</p>
                    <p>{investorDetails?.investor?.bankName}</p>
                  </span>
                  <span>
                    <p>Account Name</p>
                    <p>{investorDetails?.investor?.accountName}</p>
                  </span>
                  <span>
                    <p>Account Number</p>
                    <p>{investorDetails?.investor?.accountNumber}</p>
                  </span>
                  <span>
                    <p> Next Of Kin Full Name:</p>
                    <p>{investorDetails?.investor?.nextOfKinName}</p>
                  </span>
                  <span>
                    <p> Next Of Kin Number:</p>
                    <p>{investorDetails?.investor?.nextOfKinNum}</p>
                  </span>
                  <span>
                    <p> Next Of Kin Address:</p>
                    <p>{investorDetails?.investor?.nextOfKinAddress}</p>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {editOverlay && (
        <div className="investor-edit-overlay">
          <form className="card">
            <label>
              First Name
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Middle Name
              <input
                type="text"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Home address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Phone Number
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </label>
            <label>
              NIN
              <input
                type="tel"
                name="NIN"
                value={formData.NIN}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Suscription Amount
              <input
                type="tel"
                name="subscriptionAmount"
                value={formData.subscriptionAmount}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Bank Name
              <input
                type="tel"
                name="bankName"
                value={formData.bankName}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Account Name
              <input
                type="tel"
                name="accountName"
                value={formData.accountName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Account Number
              <input
                type="tel"
                name="saccountNumber"
                value={formData.accountNumber}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Next of Kin Name
              <input
                type="text"
                name="nextOfKinName"
                value={formData.nextOfKinName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Next of Kin Number
              <input
                type="tel"
                name="nextOfKinNum"
                value={formData.nextOfKinNum}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Next of Kin Address
              <input
                type="text"
                name="nextOfKinAddress"
                value={formData.nextOfKinAddress}
                onChange={handleInputChange}
              />
            </label>

            <div className="btn">
              <button onClick={handleCancelEdit}>Cancel</button>
              <button onClick={handleUpdateInvestor}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}

      {deleteOverlay && (
        <div className="delete-investor-overlay">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this account? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeactivateInvestor}>
                {deactivateInvestor.isLoading ? "Deactivating..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="super-admin-investor">
        <div className="header">
          <p>Stex's Investor</p>
          <div className="in">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="search investor by phone"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/superadmincreateinvestor")}>
              Create Investor
            </button>
          </div>
        </div>

        <div className="stex-investor-table">
          {isLoading && <Spinner />}
          {isError && <p className="error">Error loading investors.</p>}
          {!isLoading && !isError && filteredInvestors?.length === 0 && (
            <p>No investors found.</p>
          )}
          {!isLoading && !isError && filteredInvestors?.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Image</th>
                  <th>First</th>
                  <th>Middle</th>
                  <th>Last</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Amount</th>
                  <th>Details</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredInvestors.map((investor, index) => (
                  <tr
                    key={investor._id}
                    ref={
                      filteredInvestors.length === index + 1
                        ? lastInvestorRef
                        : null
                    }
                  >
                    <td>{investor._id.slice(-4)}</td>
                    <td>
                      <img
                        src={investor.images?.[0] || "/placeholder.png"}
                        alt="Investor"
                      />
                    </td>
                    <td>{investor.firstName}</td>
                    <td>{investor.middleName}</td>
                    <td>{investor.lastName}</td>
                    <td>{investor.address}</td>
                    <td>{investor.phoneNumber}</td>
                    <td>{investor.email}</td>
                    <td>
                      {" "}
                      {investor?.subscriptionAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </td>

                    <td>
                      <button onClick={() => handleViewDetails(investor)}>
                        View
                      </button>
                    </td>
                    <td>
                      <div className="action">
                        <CiEdit onClick={() => handleEditClick(investor)} />
                        <TrashIcon
                          onClick={() => {
                            setSelectedInvestorId(investor._id);
                            setDeleteOverlay(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </>
  );
};

export default Superadmininvestor;
