import React from "react";
import { useNavigate } from "react-router-dom";
import useFormData from "../../Context/UseFormData";
import useAllStates from "../../libs/getAllStates";
import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100.svg";
import "./Ship.css";

const Sender = () => {
  const navigate = useNavigate();
  const [formData, handleChange] = useFormData("shipFormData");
  const { data: allStates } = useAllStates();



  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    navigate("/PackageReceiver");
  };

  return (
    <div className="shipping-form-container">
      <h3>SHIP NOW</h3>
      <p>
        Packages and pallets, big and small, we can offer you instant delivery
        options for your shipping needs, Fill out your shipment details below
        and we’ll provide services tailored to your specific requirements.
        Simply pick the option that suits you best, and continue to book.
      </p>
      <div className="shipping-line"></div>
      <div className="shipping-card">
        <ShippingFrame />
      </div>
      <span>
        <p>where are you shipping from</p>
      </span>
      <form onSubmit={handleSubmit}>
        <div className="shipping-combination">
          <span>
            <label className="required-label">Full Name or Company Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={formData.name || ""}
              onChange={handleChange}
              required
            />
          </span>

          <span>
            <label>Contact Name</label>
            <input
              type="text"
              name="contact_name"
              placeholder="Enter Name"
              value={formData.contact_name || ""}
              onChange={handleChange}
              required
            />
          </span>
        </div>

        <div className="shipping-combination-address">
          <span>
            <label className="required-label">Address 1</label>
            <input
              type="text"
              name="address_1"
              placeholder="Enter Address"
              value={formData.address_1 || ""}
              onChange={handleChange}
              required
            />
          </span>

          <span>
            <label>Address 2</label>
            <input
              type="text"
              name="address_2"
              placeholder="Enter Address"
              value={formData.address_2 || ""}
              onChange={handleChange}
              required
            />
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label className="required-label"> Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email || ""}
              onChange={handleChange}
              required
            />
          </span>

          <span>
            <label>Phone Number</label>
            <div className="input-wrapper">
              <div className="zip-code">
                +234 <i className="fa-solid fa-caret-down"></i>
              </div>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number || ""}
                onChange={handleChange}
                required
              />
            </div>
          </span>
        </div>

        <div className="shipping-combination">
          <span>
            <label className="required-label">State</label>
             <select
                name="state"
                value={formData.state || ""}
                onChange={handleChange}
                required
              >
                <option value="">Select State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
          </span>

          <span>
            <label className="required-label">Zip Code</label>
            <input
              type="tel"
              name="zip_code"
              placeholder="Enter zip code"
              value={formData.zip_code || ""}
              onChange={handleChange}
              required
            />
          </span>
        </div>
        <button>Proceed</button>
      </form>
    </div>
  );
};

export default Sender;








// import React from "react";
// import { useNavigate } from "react-router-dom";
// import useFormData from "../../Context/UseFormData";
// import useStatesAndCities from "../../libs/getStateAndCities";
// import { ReactComponent as ShippingFrame } from "../../Assets/Frame 100.svg";
// import "./Ship.css";

// const Sender = () => {
//   const navigate = useNavigate();
//   const [formData, handleChange] = useFormData("shipFormData");
//   const { data: statesAndCities } = useStatesAndCities();


//   const getCitiesForState = (stateId) => {
//     const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
//     return selectedState?.cities || [];
//   };

//   const getStateNameById = (stateId) => {
//     const state = statesAndCities?.states.find((state) => state._id === stateId);
//     return state?.name || "";
//   };

//   const getCityNameById = (stateId, cityId) => {
//     const city = getCitiesForState(stateId).find((city) => city._id === cityId);
//     return city?.name || "";
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const senderStateName = getStateNameById(formData.senderState);
//     const senderCityName = getCityNameById(formData.senderState, formData.senderCity);

//     const updatedFormData = {
//       ...formData,
//       senderState: senderStateName,
//       senderCity: senderCityName,
//     };
    

//     console.log("Form Data:", updatedFormData);

//     navigate("/PackageReceiver", { state: updatedFormData });
//   };

//   return (
//     <div className="shipping-form-container">
//       <h3>SHIP NOW</h3>
//       <p>
//         Packages and pallets, big and small, we can offer you instant delivery
//         options for your shipping needs, Fill out your shipment details below
//         and we’ll provide services tailored to your specific requirements.
//         Simply pick the option that suits you best, and continue to book.
//       </p>
//       <div className="shipping-line"></div>
//       <div className="shipping-card">
//         <ShippingFrame />
//       </div>
//       <span>
//         <p>where are you shipping from</p>
//       </span>
//       <form onSubmit={handleSubmit}>
//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Full Name or Company Name</label>
//             <input
//               type="text"
//               name="name"
//               placeholder="Enter Name"
//               value={formData.name || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>

//           <span>
//             <label>Contact Name</label>
//             <input
//               type="text"
//               name="contact_name"
//               placeholder="Enter Name"
//               value={formData.contact_name || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>
//         </div>

//         <div className="shipping-combination-address">
//           <span>
//             <label className="required-label">Address 1</label>
//             <input
//               type="text"
//               name="address_1"
//               placeholder="Enter Address"
//               value={formData.address_1 || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>

//           <span>
//             <label>Address 2</label>
//             <input
//               type="text"
//               name="address_2"
//               placeholder="Enter Address"
//               value={formData.address_2 || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label className="required-label"> Email Address</label>
//             <input
//               type="email"
//               name="email"
//               placeholder="Enter email"
//               value={formData.email || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>

//           <span>
//             <label>Phone Number</label>
//             <div className="input-wrapper">
//               <div className="zip-code">
//                 +234 <i className="fa-solid fa-caret-down"></i>
//               </div>
//               <input
//                 type="tel"
//                 name="phone_number"
//                 value={formData.phone_number || ""}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </span>
//         </div>

//         <div className="shipping-combination">
//           <span>
//             <label className="required-label"> Sender State</label>
//             <select
//                   name="senderState"
//                   value={formData.senderState}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="">Select Origin State</option>
//                   {statesAndCities?.states.map((state) => (
//                     <option key={state._id} value={state._id}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </select>
//           </span>
//           <span>
//             <label className="required-label"> Sender State</label>
//             <select
//                   name="senderCity"
//                   value={formData.senderCity}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="">Select Origin City</option>
//                   {getCitiesForState(formData.senderState).map((city) => (
//                     <option key={city._id} value={city._id}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </select>
//           </span>

//         </div>
//         <div className="shipping-combination">
//           <span>
//             <label className="required-label">Zip Code</label>
//             <input
//               type="tel"
//               name="zip_code"
//               placeholder="Enter zip code"
//               value={formData.zip_code || ""}
//               onChange={handleChange}
//               required
//             />
//           </span>
//         </div>
//         <button>Proceed</button>
//       </form>
//     </div>
//   );
// };

// export default Sender;
