import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const getShippingCost = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/statecalc/getcosts?page=${pageParam}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch Local shipping cost');
  }

  return response.json();
};

const useGetLocalShippingCost = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['shippingCost'], 
    ({ pageParam = 1 }) => getShippingCost({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalShippingCosts } = lastPage; 
        const totalPages = Math.ceil(totalShippingCosts / 10); 
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useGetLocalShippingCost;








