import { useQuery } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useLogisticsRevenueData = () => {
  const { token, user } = useContext(AuthContext);

  const fetchRevenueData = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/metrics/${user.userId}`, {
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch revenue data');
    }
    return response.json();
  };

  return useQuery('revenueData', fetchRevenueData);
};

export default useLogisticsRevenueData;
