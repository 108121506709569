
import { useQuery } from 'react-query';
import axios from 'axios';

const useStatesAndCities = (options = {}) => {
  const fetchStatesAndCities = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/statecities/states`);
    return data;
  };

  return useQuery('statesAndCities', fetchStatesAndCities, {
    ...options,
    onError: (error) => {
      console.error('Error fetching states and cities:', error);
    },
  });
};

export default useStatesAndCities;

